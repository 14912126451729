import { ICON_STYLE } from '../../icons';

export const COMPONENT_SEPARATOR = {
    separator: {
        componentName: 'MCSeparator',
        noContainer: true,
        properties: [
            {
                title: 'Стиль',
                icon: ICON_STYLE,
                settings: [
                    {
                        type: 'switch',
                        title: 'Размер',
                        size: '1-2',
                        struct: 'data.separator.wide',
                        defaultValue: false,
                        options: ['Узкий', 'Широкий'],
                        values: [false, true],
                        width: 200,
                    },
                ],
            },
        ],
    },
};
