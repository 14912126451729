import { COMPONENT_BUTTON } from './component/button';
import { COMPONENT_BUTTON_SECONDARY } from './component/button-secondary';
import { COMPONENT_BUTTON_TRANSPARENT } from './component/button-transparent';
import { COMPONENT_HEADER } from './component/header';
import { COMPONENT_LABEL } from './component/label';
import { COMPONENT_IMAGE } from './component/image';
import { COMPONENT_SEPARATOR } from './component/separator';

export const COMPONENT_SETTINGS = {
    ...COMPONENT_HEADER,
    ...COMPONENT_BUTTON,
    ...COMPONENT_BUTTON_SECONDARY,
    ...COMPONENT_BUTTON_TRANSPARENT,
    ...COMPONENT_LABEL,
    ...COMPONENT_IMAGE,
    ...COMPONENT_SEPARATOR,
};
