import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { globalsCloseModal } from '../store/globals/actions';
import { deleteApp } from '../services/axios';
import { appsDeleteApp } from '../store/apps/actions';
import Modal from './Modal';
import Button from './Button';

const ModalDeleteApp = ({ id, title }) => {
    const dispatch = useDispatch();

    const deleteThisApp = async () => {
        closeModal();

        const deleteResult = await deleteApp(id);
        if (deleteResult.result === 'success') {
            dispatch(appsDeleteApp(id));
        }
    };

    const closeModal = () => {
        dispatch(globalsCloseModal());
    };

    return (
        <Modal
            id="delete-app"
            title="Удалить приложение"
            width={500}
            bodyBottom={24}
            rightButtons={[
                <Button key="panel-add-btn-add" type="danger" clickFunc={deleteThisApp}>
                    Удалить
                </Button>,
                <Button key="panel-add-btn-cancel" type="secondary" clickFunc={closeModal}>
                    Отмена
                </Button>,
            ]}
        >
            Вы дейстительно хотите удалить «<span className="modal_type_delete-app__sub-title">{title}</span>»?
        </Modal>
    );
};

ModalDeleteApp.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
};

export default ModalDeleteApp;
