import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DRAG_ZONE_HEIGHT } from '../services/locals';
import { COMPONENTS_HEADERS } from '../constants/components/components-headers';
import {
    editorComponentCopy,
    editorComponentDelete,
    editorComponentDown,
    editorComponentPreview,
    editorComponentUp,
    editorSelectComponent,
} from '../store/editor/actions';
import DraggingZone from './DraggingZone';
import EditorComponents from '../classes/EditorComponents';
import '../../styles/constructor-component.scss';

const ConstructorComponent = ({ isVisible, num, name, data }) => {
    const dispatch = useDispatch();
    const component = useSelector(state => state.editor.panels[state.editor.currentPanel].components[num]);
    const panels = useSelector(state => state.editor.panels);
    const isOnDraggingZone = useSelector(state => state.editor.isOnDraggingZone);
    const hoverComponentNum = useSelector(state => state.editor.hoverComponentNum);
    const hoverComponentOverTop = useSelector(state => state.editor.hoverComponentOverTop);
    const selectedComponentNum = useSelector(state => state.editor.selectedComponentNum);
    const isDarkTheme = useSelector(state => state.editor.isDarkTheme);
    const [preparedComponent, setPreparedComponent] = useState(null);

    useEffect(() => {
        const preparedData = EditorComponents().getComponentPreparedDataAndStyle(data, num);
        setPreparedComponent(EditorComponents().getComponent(name, preparedData, num, isDarkTheme));
    }, [component, panels, isDarkTheme]);

    return (
        <div
            className={`constructor-component${
                num === selectedComponentNum ? ' constructor-component_is-selected' : ''
            }`}
            num={num}
        >
            {hoverComponentOverTop && (
                <DraggingZone isOn={isOnDraggingZone} height={DRAG_ZONE_HEIGHT} isHide={num !== hoverComponentNum} />
            )}
            <div
                className={`constructor-component__component${
                    !isVisible ? ' constructor-component__component_is-invisible' : ''
                }`}
                onClick={() => dispatch(editorSelectComponent(num))}
            >
                {preparedComponent}
            </div>
            {!hoverComponentOverTop && (
                <DraggingZone isOn={isOnDraggingZone} height={DRAG_ZONE_HEIGHT} isHide={num !== hoverComponentNum} />
            )}
            <div className="constructor-component__control-left">
                <div className="constructor-component__delete" onClick={() => dispatch(editorComponentDelete(num))}>
                    <div />
                </div>
                {!COMPONENTS_HEADERS.includes(component.name) && (
                    <div className="constructor-component__copy" onClick={() => dispatch(editorComponentCopy(num))}>
                        <div />
                    </div>
                )}
                <div
                    className={`constructor-component__preview${
                        !isVisible ? ' constructor-component__preview_is-invisible' : ''
                    }`}
                    onClick={() => dispatch(editorComponentPreview(num))}
                >
                    <div />
                </div>
            </div>
            {!COMPONENTS_HEADERS.includes(component.name) && (
                <div className="constructor-component__control-right">
                    <div className="constructor-component__down" onClick={() => dispatch(editorComponentDown(num))}>
                        <div />
                    </div>
                    <div className="constructor-component__mid" />
                    <div className="constructor-component__up" onClick={() => dispatch(editorComponentUp(num))}>
                        <div />
                    </div>
                </div>
            )}
            <div className="constructor-component__border" />
        </div>
    );
};

ConstructorComponent.propTypes = {
    component: PropTypes.node,
    isVisible: PropTypes.bool,
    num: PropTypes.number,
    name: PropTypes.string,
    data: PropTypes.object,
};

export default ConstructorComponent;
