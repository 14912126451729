import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { editorSelectComponent } from '../store/editor/actions';
import '../../styles/components-list.scss';

const ComponentsListItem = ({ title, num, icon }) => {
    const dispatch = useDispatch();
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleMoveOutside = event => {
            const el = document.querySelector(`.constructor-component[num="${num}"]`);
            if (wrapperRef && !wrapperRef.current.contains(event.target)) {
                if (el.classList.contains('hover')) {
                    el.classList.remove('hover');
                }
            } else if (wrapperRef && wrapperRef.current.contains(event.target)) {
                if (!el.classList.contains('hover')) {
                    el.classList.add('hover');
                }
            }
        };

        document.addEventListener('mouseover', handleMoveOutside);

        return () => {
            document.removeEventListener('mouseover', handleMoveOutside);
        };
    }, []);

    const selectComponent = () => {
        const el = document.querySelector(`.constructor-component[num="${num}"]`);
        if (el.classList.contains('hover')) {
            el.classList.remove('hover');
        }
        dispatch(editorSelectComponent(num));
    };

    return (
        <div ref={wrapperRef} className="components-list__item" onClick={selectComponent}>
            <div className="components-list__left-side">
                {icon !== '' && icon !== undefined && (
                    <div
                        className="components-list__left-icon"
                        style={{ maskImage: `url(${icon})`, WebkitMaskImage: `url(${icon})` }}
                    />
                )}
                <div className="components-list__left-title">{title}</div>
            </div>
            <div className="components-list__chevron" />
        </div>
    );
};

ComponentsListItem.propTypes = {
    title: PropTypes.string,
    num: PropTypes.number,
};

export default ComponentsListItem;
