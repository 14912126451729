import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/upload-screenshots.scss';

const UploadScreenshots = ({ screens = [] }) => {
    const renderScreenshots = () =>
        screens.map(screen => (
            <div className="upload-screenshots__screen" style={{ backgroundImage: `url(${screen})` }} key={screen}>
                <div className="upload-screenshots__screen-close-icon" />
            </div>
        ));

    return (
        <div className="upload-screenshots">
            <div className="upload-screenshots__upload">
                <div className="upload-screenshots__upload-inside" />
            </div>
            {renderScreenshots()}
        </div>
    );
};

UploadScreenshots.propTypes = {
    screens: PropTypes.array,
};

export default UploadScreenshots;
