import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ICON_CAMERA } from '../constants/icons';
import { toBase64 } from '../services/functions';
import Button from './Button';
import '../../styles/input.scss';

const InputImage = ({
    placeholder = 'Загрузить изображение',
    bottomTitle,
    defaultValue,
    changeFunc,
    icon = ICON_CAMERA,
    width = 160,
    height = 90,
}) => {
    const [value, setValue] = useState(defaultValue);
    const fileRef = useRef(null);

    const openUploader = () => {
        fileRef.current.value = '';
        if (!/safari/i.test(navigator.userAgent)) {
            fileRef.current.type = '';
            fileRef.current.type = 'file';
        }
        fileRef.current.click();
    };

    const uploadImage = async e => {
        const image = await toBase64(e.target.files[0]);
        setValue(`url('${image}')`);

        if (changeFunc !== undefined) {
            changeFunc(`url('${image}')`);
        }
    };

    const clearImage = () => {
        setValue('');

        fileRef.current.value = '';
        if (!/safari/i.test(navigator.userAgent)) {
            fileRef.current.type = '';
            fileRef.current.type = 'file';
        }

        if (changeFunc !== undefined) {
            changeFunc('');
        }
    };

    return (
        <div className="input-image">
            <input
                className="input-image__input"
                type="file"
                ref={fileRef}
                accept="image/x-png,image/jpeg"
                onChange={uploadImage}
            />
            <Button type="secondary" icon={icon} clickFunc={openUploader}>
                {placeholder}
            </Button>
            {value !== undefined && value !== '' && (
                <div className="input-image__preview">
                    <div
                        className="input-image__image"
                        style={{ width: `${width}px`, height: `${height}px`, backgroundImage: value }}
                    />
                    <div className="input-image__icon-clear" onClick={clearImage} />
                </div>
            )}
            {bottomTitle !== undefined && bottomTitle !== '' && (
                <div className="input-image__bottom">{bottomTitle}</div>
            )}
        </div>
    );
};

InputImage.propTypes = {
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    changeFunc: PropTypes.func,
    bottomTitle: PropTypes.string,
    bottom: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default InputImage;
