import * as types from './actionTypes';

export const globalsSetProjectTimeout = saveProjectTimeout => ({
    type: types.GLOBALS_SET_PROJECT_TIMEOUT,
    saveProjectTimeout,
});

export const globalsSetModal = modal => ({
    type: types.GLOBALS_SET_MODAL,
    modal,
});

export const globalsCloseModal = () => ({
    type: types.GLOBALS_CLOSE_MODAL,
});

export const globalsSetConstructorTheme = constructorTheme => ({
    type: types.GLOBALS_SET_CONSTRUCTOR_THEME,
    constructorTheme,
});

export const globalsSetPageNum = pageNum => ({
    type: types.GLOBALS_SET_PAGE_NUM,
    pageNum,
});

export const globalsSetIsLoadingSomething = isLoadingSomething => ({
    type: types.GLOBALS_SET_IS_LOADING_SOMETHING,
    isLoadingSomething,
});

export const globalsSetIsProjectLoading = isProjectLoading => ({
    type: types.GLOBALS_SET_IS_PROJECT_LOADING,
    isProjectLoading,
});

export const globalsSetVkAppId = vkAppId => ({
    type: types.GLOBALS_SET_VK_APP_ID,
    vkAppId,
});
