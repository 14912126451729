import React from 'react';
import { useDispatch } from 'react-redux';
import { globalsSetModal } from '../store/globals/actions';
import ModalCreateApp from './ModalCreateApp';

const MyAppCreate = () => {
    const dispatch = useDispatch();

    const createNewApp = () => {
        dispatch(globalsSetModal(<ModalCreateApp />));
    };

    return (
        <div className="my-app my-app_create" onClick={createNewApp}>
            Создать новое приложение
        </div>
    );
};

export default MyAppCreate;
