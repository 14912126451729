import { PROPERTIES_BUTTON } from '../settings-and-properties/properties/button';

export const COMPONENT_BUTTON_SECONDARY = {
    button_secondary: {
        componentName: 'MCButton',
        ...PROPERTIES_BUTTON('button', [
            ['rgba(0, 29, 61, 0.05)', '#3f8ae0'],
            ['#454647', '#e1e3e6'],
        ]),
    },
};
