import { ICON_CLICK, ICON_STYLE, ICON_TEXT } from '../../../icons';
import { SETTINGS_OPEN_PANEL } from '../settings/text-style';

export const PROPERTIES_BUTTON = (
    alias,
    buttonColors = [
        ['#4986cc', '#ffffff'],
        ['#e1e3e6', '#19191a'],
    ],
) => ({
    properties: [
        {
            title: 'Текст',
            icon: ICON_TEXT,
            settings: [
                {
                    type: 'input-text',
                    title: 'Текст кнопки',
                    size: '1-1',
                    structLight: `data.${alias}.text`,
                    structDark: `data.${alias}.text`,
                    placeholder: 'Введите текст кнопки',
                    defaultValue: 'Кнопка',
                },
            ],
        },
        {
            title: 'Нажатие кнопки',
            icon: ICON_CLICK,
            ...SETTINGS_OPEN_PANEL(alias),
        },
        {
            title: 'Стиль',
            icon: ICON_STYLE,
            settings: [
                {
                    type: 'select',
                    title: 'Расположение',
                    size: '1-2',
                    structLight: 'style.container.bright_light.textAlign',
                    structDark: 'style.container.space_gray.textAlign',
                    defaultValue: 'left',
                    selectValues: {
                        center: 'По центру',
                        right: 'Справа',
                        left: 'Слева',
                    },
                    isOneProperty: true,
                },
                {
                    type: 'select',
                    title: 'Размер',
                    size: '1-2',
                    structLight: `data.${alias}.size`,
                    structDark: `data.${alias}.size`,
                    defaultValue: 'xl',
                    selectValues: {
                        m: 'Стандартный',
                        l: 'Большой',
                        xl: 'Огромный',
                    },
                },
                {
                    type: 'input-color',
                    title: 'Цвет кнопки',
                    size: '1-2',
                    structLight: `style.${alias}.bright_light.backgroundColor`,
                    structDark: `style.${alias}.space_gray.backgroundColor`,
                    defaultValueLight: buttonColors[0][0],
                    defaultValueDark: buttonColors[1][0],
                },
                {
                    type: 'input-color',
                    title: 'Цвет надписи',
                    size: '1-2',
                    structLight: `style.${alias}.bright_light.color`,
                    structDark: `style.${alias}.space_gray.color`,
                    defaultValueLight: buttonColors[0][1],
                    defaultValueDark: buttonColors[1][1],
                },
            ],
        },
    ],
});
