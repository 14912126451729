import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApps } from '../services/axios';
import { appsSetApps } from '../store/apps/actions';
import MyApp from './MyApp';
import MyAppCreate from './MyAppCreate';
import '../../styles/my-apps-list.scss';

const MyAppsList = () => {
    const dispatch = useDispatch();
    const apps = useSelector(state => state.apps.apps);

    useEffect(() => {
        (async () => {
            const apps = await getApps();
            if (apps.result === 'success') {
                dispatch(appsSetApps(apps.apps));
            }
        })();
    }, []);

    const renderList = () => [
        <MyAppCreate key={'app-create'} />,
        ...apps.map(app => <MyApp key={+app.id} id={+app.id} title={app.title} members={+app.members} />),
    ];

    return <div className="my-apps-list">{renderList()}</div>;
};

export default MyAppsList;
