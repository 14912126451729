import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/switch.scss';

const Switch = ({ options, icons, values, defaultValue, changeFunc, width = 130 }) => {
    const [selectedFirst, setSelectedFirst] = useState(defaultValue === values[0]);

    const selectOption = isFirst => {
        setSelectedFirst(isFirst);

        if (isFirst) {
            changeFunc(values[0]);
        } else {
            changeFunc(values[1]);
        }
    };

    const renderIcon1 = () => (
        <div
            className="switch__icon"
            style={{
                maskImage: `url(${icons[0]})`,
                WebkitMaskImage: `url(${icons[0]})`,
            }}
        />
    );

    const renderIcon2 = () => (
        <div
            className="switch__icon"
            style={{
                maskImage: `url(${icons[1]})`,
                WebkitMaskImage: `url(${icons[1]})`,
            }}
        />
    );

    return (
        <div className="switch" style={{ width: `${width}px` }}>
            <div className="switch__container">
                <div
                    className={`switch__option${selectedFirst ? ' switch__option_selected' : ''}`}
                    onClick={() => selectOption(true)}
                >
                    {icons === undefined && options[0]}
                    {icons !== undefined && renderIcon1()}
                </div>
                <div
                    className={`switch__option${!selectedFirst ? ' switch__option_selected' : ''}`}
                    onClick={() => selectOption(false)}
                >
                    {icons === undefined && options[1]}
                    {icons !== undefined && renderIcon2()}
                </div>
            </div>
        </div>
    );
};

Switch.propTypes = {
    title: PropTypes.string,
    options: PropTypes.array,
    icons: PropTypes.array,
    values: PropTypes.array,
    isSelectedFirst: PropTypes.bool,
    selectFunc: PropTypes.func,
    width: PropTypes.number,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
};

export default Switch;
