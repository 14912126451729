import React from 'react';
import { store } from '../../index';
import {
    editorLoadComponent,
    editorLoadPanel,
    editorRemoveZeroPanel,
    editorSelectPanel,
    editorSetIsDarkTheme,
    editorSetIsIOS,
    editorSetPhoneScreen,
    editorSetProjectKey,
} from '../store/editor/actions';
import EditorComponents from './EditorComponents';

const ProjectJSONLoaderAndSaver = () => {
    const generate = () => {
        const editor = store.getState().editor;

        const panels = {};
        Object.keys(editor.panels).forEach(panelKey => {
            const components = [];

            editor.panels[panelKey].components.forEach(component => {
                const preparedData = EditorComponents().getComponentPreparedDataAndStyle(component.data);
                components.push({
                    type: component.name,
                    is_visible: component.isVisible,
                    component_name: component.componentName,
                    ...preparedData,
                });
            });

            panels[panelKey] = {
                is_startup: editor.panels[panelKey].isStartup,
                has_header: editor.panels[panelKey].hasHeader,
                title: editor.panels[panelKey].title,
                components,
            };
        });

        return {
            project: {
                os: editor.isIOS ? 'ios' : 'android',
                theme: editor.isDarkTheme ? 'space_gray' : 'bright_light',
                phone: editor.phoneScreen,
            },
            panels,
        };
    };

    const prepareJSONDataToStoreData = (type, jsonData, jsonStyle, jsonActions) => {
        const defaultData = EditorComponents().getComponentDefaultData(type);

        // Data
        if (jsonData !== undefined) {
            Object.keys(jsonData).forEach(subComponentName => {
                Object.keys(jsonData[subComponentName]).forEach(dataKey => {
                    defaultData[`data.${subComponentName}.${dataKey}`] = jsonData[subComponentName][dataKey];
                });
            });
        }

        // Style
        if (jsonStyle !== undefined) {
            Object.keys(jsonStyle).forEach(subComponentName => {
                Object.keys(jsonStyle[subComponentName]).forEach(dataKey => {
                    Object.keys(jsonStyle[subComponentName][dataKey]).forEach(themeKey => {
                        defaultData[`style.${subComponentName}.${dataKey}.${themeKey}`] =
                            jsonStyle[subComponentName][dataKey][themeKey];
                    });
                });
            });
        }

        // Actions
        if (jsonActions !== undefined) {
            Object.keys(jsonActions).forEach(subComponentName => {
                jsonActions[subComponentName].forEach((action, num) => {
                    const actionName = `${action.action}#${num + 1}`;
                    Object.keys(action).forEach(dataKey => {
                        if (dataKey !== 'action') {
                            if (dataKey === 'data') {
                                Object.keys(action[dataKey]).forEach(subDataKey => {
                                    defaultData[`actions.${subComponentName}.${actionName}.${dataKey}.${subDataKey}`] =
                                        action[dataKey][subDataKey];
                                });
                            } else {
                                defaultData[`actions.${subComponentName}.${actionName}.${dataKey}`] = action[dataKey];
                            }
                        }
                    });
                });
            });
        }

        return defaultData;
    };

    const loadProject = (dispatch, appJSON) => {
        appJSON = JSON.parse(appJSON);

        dispatch(editorSetIsDarkTheme(appJSON.project.theme === 'space_gray'));
        dispatch(editorSetIsIOS(appJSON.project.os === 'ios'));
        dispatch(editorSetPhoneScreen(appJSON.project.phone));

        let defaultPanel;
        Object.keys(appJSON.panels).forEach(panelKey => {
            if (appJSON.panels[panelKey].is_startup) {
                defaultPanel = panelKey;
            }

            dispatch(
                editorLoadPanel(panelKey, {
                    ...appJSON.panels[panelKey],
                }),
            );

            appJSON.panels[panelKey].components.forEach(component => {
                dispatch(
                    editorLoadComponent(
                        {
                            componentName: component.component_name,
                            isVisible: component.is_visible,
                            component: EditorComponents().getComponent(component.type),
                            title: EditorComponents().getComponentTitle(component.type),
                            images: EditorComponents().getComponentImages(component.type),
                            icon: EditorComponents().getComponentIcon(component.type),
                            data: prepareJSONDataToStoreData(
                                component.type,
                                component.data,
                                component.style,
                                component.actions,
                            ),
                            name: component.type,
                        },
                        panelKey,
                    ),
                );
            });
        });

        dispatch(editorSelectPanel(defaultPanel));
        dispatch(editorRemoveZeroPanel());
        dispatch(editorSetProjectKey(Math.random()));
    };

    return { loadProject, generate };
};

export default ProjectJSONLoaderAndSaver;
