import { ICON_HEADER } from '../../icons';
import { SETTINGS_OPEN_PANEL } from '../settings-and-properties/settings/text-style';

export const COMPONENT_HEADER = {
    header: {
        componentName: 'MCHeader',
        noContainer: true,
        properties: [
            {
                title: 'Шапка',
                icon: ICON_HEADER,
                settings: [
                    {
                        type: 'input-text',
                        title: 'Заголовок',
                        size: '1-1',
                        structLight: 'data.header.title',
                        structDark: 'data.header.title',
                        placeholder: 'Введите загловок',
                        defaultValue: 'Заголовок',
                    },
                    {
                        type: 'input-text',
                        title: 'Подзаголовок',
                        size: '1-1',
                        structLight: 'data.header.status',
                        structDark: 'data.header.status',
                        placeholder: 'Введите подзагловок',
                        defaultValue: '',
                    },
                    {
                        type: 'switch',
                        title: 'Кнопка назад',
                        size: '1-1',
                        structLight: 'data.back-button.show',
                        structDark: 'data.back-button.show',
                        placeholder: 'Введите подзагловок',
                        defaultValue: false,
                        options: ['Вкл', 'Выкл'],
                        values: [true, false],
                        width: 130,
                    },
                    ...SETTINGS_OPEN_PANEL('back-button').settings,
                ],
            },
        ],
    },
};
