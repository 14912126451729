import React from 'react';
import { ALL_ICONS_AND_IMAGES } from '../constants/icons';
import { ALL_COMPONENTS_IMAGES } from '../constants/components/images';

const ImagesPreloader = () => {
    const preload = () => {
        ALL_ICONS_AND_IMAGES.forEach(url => (new Image().src = url));
        ALL_COMPONENTS_IMAGES.forEach(url => (new Image().src = url));
    };

    return {
        preload,
    };
};

export default ImagesPreloader;
