import * as types from './actionTypes';

export const authSetVkIsConnected = vkIsConnected => ({
    type: types.AUTH_SET_VK_IS_CONNECTED,
    vkIsConnected,
});

export const authSetFirstAuthChecked = firstAuthChecked => ({
    type: types.AUTH_SET_FIRST_AUTH_CHECKED,
    firstAuthChecked,
});

export const authSetPrevPage = (prevPage, prevPageParams) => ({
    type: types.AUTH_SET_PREV_PAGE,
    prevPage,
    prevPageParams,
});

export const authSetSettingsLoaded = settingsLoaded => ({
    type: types.AUTH_SET_SETTINGS_LOADED,
    settingsLoaded,
});
