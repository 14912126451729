import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/dragging-zone.scss';

const DraggingZone = ({ height, isOn, isHide = false }) => {
    const zoneStyle = () => {
        if (height !== undefined) {
            return { height: `${height}px` };
        } else {
            return {};
        }
    };

    return (
        <div
            className={`dragging-zone${isOn ? ' dragging-zone_drag-over' : ''}${
                isHide ? ' dragging-zone_is-hide' : ''
            }${height === undefined ? ' dragging-zone_is-full' : ''}`}
            style={zoneStyle()}
        />
    );
};

DraggingZone.propTypes = {
    isHide: PropTypes.bool,
    height: PropTypes.number,
    isOn: PropTypes.bool,
};

export default DraggingZone;
