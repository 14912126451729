import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/empty-block.scss';

const EmptyBlock = ({ title, icon, desc, style }) => {
    return (
        <div className="empty-block" style={style !== undefined ? style : {}}>
            {icon !== undefined && (
                <div
                    className="empty-block__icon"
                    style={{ maskImage: `url(${icon})`, WebkitMaskImage: `url(${icon})` }}
                />
            )}
            <div className="empty-block__title">{title}</div>
            {desc !== undefined && <div className="empty-block__desc">{desc}</div>}
        </div>
    );
};

EmptyBlock.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    desc: PropTypes.string,
    style: PropTypes.object,
};

export default EmptyBlock;
