import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/tab.scss';

const Tab = ({ titles, contents, setSelectedTab, setTabFunc }) => {
    const [currentTab, setCurrentTab] = useState(0);

    useEffect(() => {
        if (setSelectedTab !== undefined) {
            setCurrentTab(setSelectedTab);
        }
    }, [setSelectedTab]);

    const selectTab = num => {
        setCurrentTab(num);

        if (setTabFunc !== undefined) {
            setTabFunc(num);
        }
    };

    const renderTitles = () =>
        titles.map((title, i) => (
            <div
                className={`tab__header${currentTab === i ? ' tab__header_selected' : ''}`}
                onClick={() => selectTab(i)}
                key={title}
            >
                {title}
            </div>
        ));

    const renderBodies = () =>
        contents.map((content, i) => (
            <div className={`tab__body${currentTab === i ? ' tab__body_selected' : ''}`} key={`tb-${i}`}>
                {content}
            </div>
        ));

    return (
        <div className="tab">
            <div className="tab__headers">{renderTitles()}</div>
            <div className="tab__bodies">{renderBodies()}</div>
        </div>
    );
};

Tab.propTypes = {
    titles: PropTypes.array,
    contents: PropTypes.array,
    setSelectedTab: PropTypes.number,
    setTabFunc: PropTypes.func,
};

export default Tab;
