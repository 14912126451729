import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/tapable-button.scss';

const TapableButton = ({ text, isTapedDefault, value, values, clickFunc, icon, positionType = 'full' }) => {
    const [currentTapValue, setCurrentTapValue] = useState(isTapedDefault);
    const tapValues = values !== undefined ? values : [true, false];

    useEffect(() => {
        setCurrentTapValue(value);
    }, [value]);

    const clickButton = () => {
        const nextTapValue = currentTapValue === tapValues[0] ? tapValues[1] : tapValues[0];

        if (value === undefined) {
            setCurrentTapValue(nextTapValue);
        }

        if (clickFunc !== undefined) {
            clickFunc(nextTapValue);
        }
    };

    return (
        <div
            className={`tapable-button${currentTapValue === tapValues[0] ? ` tapable-button_taped` : ''}${
                text === undefined ? ' tapable-button_only-icon' : ''
            } tapable-button_${positionType}`}
            onClick={clickButton}
        >
            {icon !== undefined && (
                <div
                    className={`tapable-button__icon`}
                    style={{ maskImage: `url(${icon})`, WebkitMaskImage: `url(${icon})` }}
                />
            )}
            {text}
        </div>
    );
};

TapableButton.propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    isTapedDefault: PropTypes.bool,
    value: PropTypes.bool,
    clickFunc: PropTypes.func,
    positionType: PropTypes.string,
};

export default TapableButton;
