import { ICON_STYLE, ICON_TEXT } from '../../icons';
import { SETTINGS_TEXT_STYLE } from '../settings-and-properties/settings/text-style';

export const COMPONENT_LABEL = {
    label: {
        componentName: 'MCLabel',
        properties: [
            {
                title: 'Текст',
                icon: ICON_TEXT,
                settings: [
                    {
                        type: 'textarea-text',
                        title: 'Текст надписи',
                        size: '1-1',
                        structLight: 'data.text.text',
                        structDark: 'data.text.text',
                        placeholder: 'Введите текст надписи',
                        defaultValue: 'Надпись',
                    },
                ],
            },
            {
                title: 'Стиль',
                icon: ICON_STYLE,
                settings: [
                    ...SETTINGS_TEXT_STYLE('text').settings,
                    {
                        type: 'input-size',
                        title: 'Размер',
                        size: '1-2',
                        structLight: 'style.text.bright_light.fontSize',
                        structDark: 'style.text.space_gray.fontSize',
                        defaultValue: '15px',
                    },
                    {
                        type: 'input-color',
                        title: 'Цвет',
                        size: '1-2',
                        structLight: 'style.text.bright_light.color',
                        structDark: 'style.text.space_gray.color',
                        defaultValueLight: '#000000',
                        defaultValueDark: '#e1e3e6',
                    },
                ],
            },
        ],
    },
};
