import React from 'react';
import PropTypes from 'prop-types';

const SpaceBlock = ({ height }) => {
    return <div className="space-block" style={{ height }} />;
};

SpaceBlock.propTypes = {
    height: PropTypes.number,
};

export default SpaceBlock;
