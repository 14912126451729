import { ICON_LIFT_UP, ICON_LIFT_DOWN, ICON_COPY, ICON_DELETE } from '../../icons';

export const COMPONENT_CONTROL_SETTINGS = {
    componentControl: {
        properties: [
            {
                headTitle: 'Управление блоком',
                settings: [
                    {
                        type: 'switch-visibility',
                        title: 'Отображение',
                        size: '1-2',
                        noStruct: true,
                    },
                    {
                        type: 'button-up',
                        size: 'min',
                        title: 'Положение',
                        noStruct: true,
                        icon: ICON_LIFT_UP,
                        buttonType: 'gray',
                        rightMargin: 0,
                        positionType: 'left',
                    },
                    {
                        type: 'button-down',
                        size: 'min',
                        title: null,
                        noStruct: true,
                        icon: ICON_LIFT_DOWN,
                        buttonType: 'gray',
                        leftMargin: 0,
                        positionType: 'right',
                    },
                    {
                        type: 'button-copy',
                        size: '1-1',
                        noStruct: true,
                        icon: ICON_COPY,
                        buttonType: 'gray',
                        children: 'Дублировать компонент',
                    },
                    {
                        type: 'button-delete',
                        size: '1-1',
                        noStruct: true,
                        icon: ICON_DELETE,
                        buttonType: 'danger',
                        children: 'Удалить',
                    },
                ],
            },
        ],
    },
};
