import React from 'react';
import { authSetPrevPage } from '../store/auth/actions';
import { globalsSetPageNum } from '../store/globals/actions';
import PageEditor from '../containers/PageEditor';
import PageLogin from '../containers/PageLogin';
import PageLoader from '../containers/PageLoader';
import PageMyApps from '../containers/PageMyApps';
import PageAuthorize from '../containers/PageAuthorize';

const Routing = () => {
    const getPanel = (dispatch, page) => {
        const pageParts = page.split('-');
        const pageNum = +pageParts.pop();
        let currentPage;

        if (Number.isInteger(pageNum)) {
            currentPage = pageParts.join('-');
            dispatch(globalsSetPageNum(pageNum));
        } else {
            currentPage = page;
            dispatch(globalsSetPageNum(-1));
        }

        switch (currentPage) {
            default:
            case '':
                dispatch(authSetPrevPage(page));
                return <PageMyApps />;

            case 'loader':
                return <PageLoader />;

            case 'login':
                return <PageLogin />;

            case 'authorization':
                return <PageAuthorize />;

            case 'edit-app':
                dispatch(authSetPrevPage(page));
                return <PageEditor />;
        }
    };

    return { getPanel };
};

export default Routing;
