import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { globalsCloseModal } from '../store/globals/actions';
import { appsNewApp } from '../store/apps/actions';
import { copyApp } from '../services/axios';
import Modal from './Modal';
import InputText from './InputText';
import MiniHead from './MiniHead';
import Button from './Button';

const ModalCopyApp = ({ id, title }) => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const defaultTitle = title;

    const copyThisApp = async () => {
        closeModal();

        const copyResult = await copyApp(id, name === '' ? defaultTitle : name);
        if (copyResult.result === 'success') {
            dispatch(appsNewApp({ id: copyResult.app.id, title: copyResult.app.title, members: 0 }));
        }
    };

    const changeName = value => {
        setName(value);
    };

    const closeModal = () => {
        dispatch(globalsCloseModal());
    };

    return (
        <Modal
            id="create-app"
            title="Создать копию приложения"
            width={500}
            bodyBottom={8}
            rightButtons={[
                <Button key="panel-add-btn-cancel" type="secondary" clickFunc={closeModal}>
                    Отмена
                </Button>,
                <Button key="panel-add-btn-add" type="primary" clickFunc={copyThisApp}>
                    Копировать
                </Button>,
            ]}
        >
            <MiniHead title="Название нового приложения" />
            <InputText placeholder={defaultTitle} defaultValue={name} changeFunc={changeName} maxLength={48} />
        </Modal>
    );
};

ModalCopyApp.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
};

export default ModalCopyApp;
