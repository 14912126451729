import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import View from '@vkontakte/vkui/dist/components/View/View';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import CustomScroll from 'react-custom-scroll';
import DraggingZone from './DraggingZone';
import ConstructorComponent from './ConstructorComponent';
import AppDefaultButtons from './AppDefaultButtons';
import ConfigProvider from '@vkontakte/vkui/dist/components/ConfigProvider/ConfigProvider';
import '../../styles/custom-scroll.scss';
import '../../styles/constructor-viewer.scss';

const ConstructorViewer = ({ osRand, width, height, minHeight, offset }) => {
    const components = useSelector(state => state.editor.panels[state.editor.currentPanel].components);
    const isOnDraggingZone = useSelector(state => state.editor.isOnDraggingZone);
    const isOnMiddleZone = useSelector(state => state.editor.isOnMiddleZone);
    const isDarkTheme = useSelector(state => state.editor.isDarkTheme);

    const renderComponents = () =>
        components.map((component, i) => (
            <ConstructorComponent
                key={i}
                component={component.component}
                isVisible={component.isVisible}
                num={i}
                name={component.name}
                data={component.data}
            />
        ));

    return (
        <div
            className="constructor-viewer"
            style={{
                height: height !== undefined ? `${height}px` : 'auto',
                width: `${width}px`,
                minHeight: `${minHeight}px`,
                transform: `translate3d(0px, ${offset}px, 0px)`,
            }}
        >
            <CustomScroll heightRelativeToParent="100%" key="cs">
                <ConfigProvider scheme={isDarkTheme ? 'space_gray' : 'bright_light'}>
                    <AppDefaultButtons />
                    <View key={osRand} activePanel="constructor-panel">
                        <Panel id="constructor-panel">
                            {components.length === 0 && isOnMiddleZone && <DraggingZone isOn={isOnDraggingZone} />}
                            {renderComponents()}
                        </Panel>
                    </View>
                </ConfigProvider>
            </CustomScroll>
        </div>
    );
};

ConstructorViewer.propTypes = {
    height: PropTypes.number,
    minHeight: PropTypes.number,
    width: PropTypes.number,
    offset: PropTypes.number,
};

export default ConstructorViewer;
