import * as types from './actionTypes';

export const editorAddComponent = component => ({
    type: types.EDITOR_ADD_COMPONENT,
    component,
});

export const editorLoadComponent = (component, panelKey) => ({
    type: types.EDITOR_LOAD_COMPONENT,
    component,
    panelKey,
});

export const editorAddPanel = (panelKey, title) => ({
    type: types.EDITOR_ADD_PANEL,
    panelKey,
    title,
});

export const editorSelectPanel = currentPanel => ({
    type: types.EDITOR_SELECT_PANEL,
    currentPanel,
});

export const editorLoadPanel = (panelKey, panel) => ({
    type: types.EDITOR_LOAD_PANEL,
    panelKey,
    panel,
});

export const editorSetStartupPanel = panelKey => ({
    type: types.EDITOR_SET_STARTUP_PANEL,
    panelKey,
});

export const editorSetPanelTitle = (panelKey, title) => ({
    type: types.EDITOR_SET_PANEL_TITLE,
    panelKey,
    title,
});

export const editorDeletePanel = panelKey => ({
    type: types.EDITOR_DELETE_PANEL,
    panelKey,
});

export const editorSetIsDragging = isDragging => ({
    type: types.EDITOR_SET_IS_DRAGGING,
    isDragging,
});

export const editorSetDragPosition = (dragPositionX, dragPositionY) => ({
    type: types.EDITOR_SET_DRAG_POSITION,
    dragPositionX,
    dragPositionY,
});

export const editorSetDragOffset = (dragOffsetX, dragOffsetY) => ({
    type: types.EDITOR_SET_DRAG_OFFSET,
    dragOffsetX,
    dragOffsetY,
});

export const editorResetDragging = () => ({
    type: types.EDITOR_RESET_DRAGGING,
});

export const editorSetIsOnDraggingZone = isOnDraggingZone => ({
    type: types.EDITOR_SET_IS_ON_DRAGGING_ZONE,
    isOnDraggingZone,
});

export const editorSetHoverComponentNum = hoverComponentNum => ({
    type: types.EDITOR_SET_HOVER_COMPONENT_NUM,
    hoverComponentNum,
});

export const editorSetHoverComponentOverTop = hoverComponentOverTop => ({
    type: types.EDITOR_SET_HOVER_COMPONENT_OVER_TOP,
    hoverComponentOverTop,
});

export const editorSetDragComponentType = dragComponentType => ({
    type: types.EDITOR_SET_DRAG_COMPONENT_TYPE,
    dragComponentType,
});

export const editorSetDragComponentOptions = dragOptions => ({
    type: types.EDITOR_SET_DRAG_COMPONENT_OPTIONS,
    dragOptions,
});

export const editorSetIsOnMiddleZone = isOnMiddleZone => ({
    type: types.EDITOR_SET_IS_ON_MIDDLE_ZONE,
    isOnMiddleZone,
});

export const editorSetConstructorViewerSize = (selectedPhone, constructorViewerSize) => ({
    type: types.EDITOR_SET_CONSTRUCTOR_VIEWER_SIZE,
    selectedPhone,
    constructorViewerSize,
});

export const editorComponentDown = num => ({
    type: types.EDITOR_COMPONENT_DOWN,
    num,
});

export const editorComponentUp = num => ({
    type: types.EDITOR_COMPONENT_UP,
    num,
});

export const editorComponentCopy = num => ({
    type: types.EDITOR_COMPONENT_COPY,
    num,
});

export const editorComponentDelete = num => ({
    type: types.EDITOR_COMPONENT_DELETE,
    num,
});

export const editorComponentPreview = (num, isVisible) => ({
    type: types.EDITOR_COMPONENT_PREVIEW,
    num,
    isVisible,
});

export const editorSelectComponent = num => ({
    type: types.EDITOR_SELECT_COMPONENT,
    num,
});

export const editorSetComponentData = (selectedComponentNum, struct, data) => ({
    type: types.EDITOR_SET_COMPONENT_DATA,
    selectedComponentNum,
    struct,
    data,
});

export const editorSetIsDarkTheme = isDarkTheme => ({
    type: types.EDITOR_SET_IS_DARK_THEME,
    isDarkTheme,
});

export const editorSetIsIOS = isIOS => ({
    type: types.EDITOR_SET_IS_IOS,
    isIOS,
});

export const editorSetPhoneScreen = phoneScreen => ({
    type: types.EDITOR_SET_PHONE_SCREEN,
    phoneScreen,
});

export const editorSetProjectKey = projectKey => ({
    type: types.EDITOR_SET_PROJECT_KEY,
    projectKey,
});

export const editorResetPanelsAndProject = () => ({
    type: types.EDITOR_RESET_PANELS_AND_PROJECT,
});

export const editorRemoveZeroPanel = () => ({
    type: types.EDITOR_REMOVE_ZERO_PANEL,
});
