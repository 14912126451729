import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { auth } from '../services/auth';
import { globalsSetIsLoadingSomething } from '../store/globals/actions';

const PageAuthorize = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            dispatch(globalsSetIsLoadingSomething(true));
            await auth(location);
            dispatch(globalsSetIsLoadingSomething(false));
        })();
    }, []);

    return <div className="page-authorize" />;
};

export default PageAuthorize;
