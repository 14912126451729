import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICON_PREVIEW, ICON_UNPREVIEW } from '../constants/icons';
import { COMPONENT_SETTINGS } from '../constants/components/components-settings';
import { CONTAINER_SETTINGS } from '../constants/components/settings-and-properties/container-settings';
import { COMPONENT_CONTROL_SETTINGS } from '../constants/components/settings-and-properties/component-control-settings';
import { COMPONENT_HEADER_CONTROL_SETTINGS } from '../constants/components/settings-and-properties/component-header-control-settings';
import { COMPONENTS_HEADERS } from '../constants/components/components-headers';
import {
    editorComponentCopy,
    editorComponentDelete,
    editorComponentDown,
    editorComponentPreview,
    editorComponentUp,
    editorSetComponentData,
} from '../store/editor/actions';
import Collapse from '../components/Collapse';
import TextareaText from '../components/TextareaText';
import InputSize from '../components/InputSize';
import MiniHead from '../components/MiniHead';
import InputText from '../components/InputText';
import InputAmount from '../components/InputAmount';
import InputColor from '../components/InputColor';
import Dropdown from '../components/Dropdown';
import Switch from '../components/Switch';
import SpaceBlock from '../components/SpaceBlock';
import Head from '../components/Head';
import InputImage from '../components/InputImage';
import Button from '../components/Button';
import TapableButton from '../components/TapableButton';
import '../../styles/properties.scss';

const ComponentSettingsRenderer = () => {
    const dispatch = useDispatch();
    const components = useSelector(state => state.editor.panels[state.editor.currentPanel].components);
    const panels = useSelector(state => state.editor.panels);
    const selectedComponentNum = useSelector(state => state.editor.selectedComponentNum);
    const isDarkTheme = useSelector(state => state.editor.isDarkTheme);

    const changeValue = (value, structLight, structDark, isOneProperty) => {
        if (isOneProperty) {
            dispatch(editorSetComponentData(selectedComponentNum, structLight, value));
            dispatch(editorSetComponentData(selectedComponentNum, structDark, value));
        } else {
            if (!isDarkTheme) {
                dispatch(editorSetComponentData(selectedComponentNum, structLight, value));
            } else {
                dispatch(editorSetComponentData(selectedComponentNum, structDark, value));
            }
        }
    };

    const clickTapableButton = (currentTap, values, structLight, structDark, isOneProperty) => {
        const value = currentTap === true ? values[0] : values[1];
        changeValue(value, structLight, structDark, isOneProperty);
    };

    const setComponentVisible = value => {
        if (value === 'visible') {
            dispatch(editorComponentPreview(selectedComponentNum, true));
        } else {
            dispatch(editorComponentPreview(selectedComponentNum, false));
        }
    };

    const upComponent = () => {
        dispatch(editorComponentUp(selectedComponentNum));
    };

    const downComponent = () => {
        dispatch(editorComponentDown(selectedComponentNum));
    };

    const copyComponent = () => {
        dispatch(editorComponentCopy(selectedComponentNum));
    };

    const deleteComponent = () => {
        dispatch(editorComponentDelete(selectedComponentNum));
    };

    const checkShowProperty = (property, data) => {
        if (property.showWhen !== undefined) {
            let isShow = false;

            property.showWhen.forEach(sw => {
                let curStructNum = isDarkTheme ? 1 : 0;
                curStructNum = sw.struct.length === 2 ? curStructNum : 0;

                if (sw.checkValues.includes(data[sw.struct[curStructNum]])) {
                    isShow = true;
                }
            });

            return isShow;
        }

        return true;
    };

    const checkHideProperty = (property, data) => {
        if (property.hideWhen !== undefined) {
            let isHide = false;

            property.hideWhen.forEach(hw => {
                let curStructNum = isDarkTheme ? 1 : 0;
                curStructNum = hw.struct.length === 2 ? curStructNum : 0;

                if (hw.checkValues.includes(data[hw.struct[curStructNum]])) {
                    isHide = true;
                }
            });

            return isHide;
        }

        return false;
    };

    const prepareSelectPanelsOptions = () => {
        const options = {};

        Object.keys(panels).forEach(pKey => {
            options[pKey] = panels[pKey].title;
        });

        return options;
    };

    const render = type => {
        const elements = [];

        if (COMPONENT_SETTINGS[type] !== undefined) {
            let componentSettings;

            let containerSettings;
            if (COMPONENT_SETTINGS[type].noContainer === undefined || COMPONENT_SETTINGS[type].noContainer === false) {
                containerSettings = [...CONTAINER_SETTINGS.container.properties];
            } else {
                containerSettings = [];
            }

            let controlSettings;
            if (!COMPONENTS_HEADERS.includes(components[selectedComponentNum].name)) {
                controlSettings = [...COMPONENT_CONTROL_SETTINGS.componentControl.properties];
            } else {
                controlSettings = [...COMPONENT_HEADER_CONTROL_SETTINGS.componentControl.properties];
            }

            componentSettings = [...COMPONENT_SETTINGS[type].properties, ...containerSettings, ...controlSettings];

            componentSettings.forEach((cs, csNum) => {
                const properties = [];

                // Добавляем заголовок
                if (cs.headTitle !== undefined) {
                    elements.push(<Head title={cs.headTitle} isPaddingTop key={cs.headTitle} />);
                }

                cs.settings.forEach((s, sNum) => {
                    if (
                        s.type !== 'default' &&
                        s.type !== 'helper' &&
                        checkShowProperty(s, components[selectedComponentNum].data) &&
                        !checkHideProperty(s, components[selectedComponentNum].data)
                    ) {
                        const structLight = s.struct !== undefined ? s.struct : s.structLight;
                        const structDark = s.struct !== undefined ? s.struct : s.structDark;

                        // Задаем свойства по-умолчанию
                        const defaultProps = {
                            key: `cs-${csNum}-s${sNum}`,
                            value: !isDarkTheme
                                ? components[selectedComponentNum].data[structLight]
                                : components[selectedComponentNum].data[structDark],
                            defaultValue: !isDarkTheme
                                ? components[selectedComponentNum].data[structLight]
                                : components[selectedComponentNum].data[structDark],
                            changeFunc: value => changeValue(value, structLight, structDark, s.isOneProperty),
                        };

                        const inside = [];

                        // Добавляем подзаголовок
                        if (s.title !== null) {
                            if (s.title !== undefined) {
                                inside.push(<MiniHead title={s.title} key={`h${structLight}`} />);
                            }
                        } else {
                            inside.push(<SpaceBlock height={15 + 8} key={`h${s.structLight}`} />);
                        }

                        // Рендерим свойство
                        switch (s.type) {
                            default:
                            case '':
                                inside.push(<></>);
                                break;

                            case 'input-text':
                                inside.push(
                                    <InputText
                                        {...defaultProps}
                                        placeholder={s.placeholder !== undefined ? s.placeholder : '0'}
                                    />,
                                );
                                break;

                            case 'input-size':
                                inside.push(
                                    <InputSize
                                        {...defaultProps}
                                        placeholder={s.placeholder !== undefined ? s.placeholder : '0'}
                                        isDecimal={true}
                                        maxDecimals={5}
                                        minValue={s.min !== undefined ? s.min : -99999999}
                                        maxValue={s.max !== undefined ? s.max : 99999999}
                                    />,
                                );
                                break;

                            case 'input-number':
                                inside.push(
                                    <InputAmount
                                        {...defaultProps}
                                        placeholder={s.placeholder !== undefined ? s.placeholder : '0'}
                                        isDecimal={s.isDecimal}
                                        maxDecimals={5}
                                        minValue={s.min !== undefined ? s.min : -99999999}
                                        maxValue={s.max !== undefined ? s.max : 99999999}
                                    />,
                                );
                                break;

                            case 'input-color':
                                inside.push(
                                    <InputColor
                                        {...defaultProps}
                                        placeholder={s.placeholder !== undefined ? s.placeholder : '0'}
                                    />,
                                );
                                break;

                            case 'select':
                                inside.push(
                                    <Dropdown
                                        {...defaultProps}
                                        options={s.selectValues}
                                        placeholder={s.placeholder !== undefined ? s.placeholder : 'Не выбрано'}
                                    />,
                                );
                                break;

                            case 'select-panels':
                                inside.push(
                                    <Dropdown
                                        {...defaultProps}
                                        options={prepareSelectPanelsOptions()}
                                        placeholder={s.placeholder !== undefined ? s.placeholder : 'Не выбрано'}
                                    />,
                                );
                                break;

                            case 'textarea-text':
                                inside.push(
                                    <TextareaText
                                        {...defaultProps}
                                        placeholder={s.placeholder !== undefined ? s.placeholder : 'Введите текст'}
                                        rows={3}
                                    />,
                                );
                                break;

                            case 'input-image':
                                inside.push(
                                    <InputImage
                                        {...defaultProps}
                                        placeholder={s.placeholder}
                                        bottomTitle={s.bottomTitle}
                                    />,
                                );
                                break;

                            case 'button-up':
                                inside.push(
                                    <Button
                                        {...defaultProps}
                                        key="btn-up"
                                        icon={s.icon}
                                        type={s.buttonType}
                                        positionType={s.positionType}
                                        clickFunc={upComponent}
                                    />,
                                );
                                break;

                            case 'button-down':
                                inside.push(
                                    <Button
                                        {...defaultProps}
                                        key="btn-down"
                                        icon={s.icon}
                                        type={s.buttonType}
                                        positionType={s.positionType}
                                        clickFunc={downComponent}
                                    />,
                                );
                                break;

                            case 'button-copy':
                                inside.push(
                                    <Button
                                        {...defaultProps}
                                        key="btn-copy"
                                        icon={s.icon}
                                        type={s.buttonType}
                                        clickFunc={copyComponent}
                                        children={s.children}
                                    />,
                                );
                                break;

                            case 'button-delete':
                                inside.push(
                                    <Button
                                        {...defaultProps}
                                        key="btn-delete"
                                        icon={s.icon}
                                        type={s.buttonType}
                                        clickFunc={deleteComponent}
                                        children={s.children}
                                    />,
                                );
                                break;

                            case 'tapable-button':
                                inside.push(
                                    <TapableButton
                                        {...defaultProps}
                                        icon={s.icon}
                                        text={s.text}
                                        value={
                                            !isDarkTheme
                                                ? s.values[0] === components[selectedComponentNum].data[structLight]
                                                : s.values[0] === components[selectedComponentNum].data[structDark]
                                        }
                                        clickFunc={currentTap =>
                                            clickTapableButton(
                                                currentTap,
                                                s.values,
                                                structLight,
                                                structDark,
                                                s.isOneProperty,
                                            )
                                        }
                                        positionType={s.positionType}
                                    />,
                                );
                                break;

                            case 'switch':
                                inside.push(
                                    <Switch
                                        {...defaultProps}
                                        values={s.values}
                                        options={s.options !== undefined ? s.options : undefined}
                                        icons={s.icons !== undefined ? s.icons : undefined}
                                        width={s.width}
                                    />,
                                );
                                break;

                            case 'switch-visibility':
                                inside.push(
                                    <Switch
                                        key={'switch-visibility'}
                                        icons={[ICON_PREVIEW, ICON_UNPREVIEW]}
                                        values={['visible', 'invisible']}
                                        defaultValue={
                                            components[selectedComponentNum].isVisible ? 'visible' : 'invisible'
                                        }
                                        changeFunc={setComponentVisible}
                                    />,
                                );
                                break;
                        }
                        properties.push(
                            <div
                                key={`p-cs-${csNum}-s${sNum}`}
                                className={`property size-${s.size !== undefined ? s.size : '1-1'}`}
                                style={{ marginRight: `${s.rightMargin}px`, marginLeft: `${s.leftMargin}px` }}
                            >
                                {inside}
                            </div>,
                        );
                    }
                });

                if (cs.title !== undefined) {
                    elements.push(
                        <Collapse title={cs.title} icon={cs.icon} isOpened key={cs.title}>
                            <div className="properties">{properties}</div>
                        </Collapse>,
                    );
                } else {
                    elements.push(
                        <div className="properties properties_top-margin" key={`pr-tm`}>
                            {properties}
                        </div>,
                    );
                }
            });
        }
        return elements;
    };

    return { render };
};

export default ComponentSettingsRenderer;
