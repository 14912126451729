import { useEffect, useRef } from 'react';
import { ERROR_UNKNOWN } from './locals';

// Скопировать массив
export const copyArray = array => JSON.parse(JSON.stringify(array));

// Хук для EventListener
export const useEventListener = (eventName, handler, element = window) => {
    const savedHandler = useRef();

    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) {
            return;
        }

        const eventListener = event => savedHandler.current(event);

        element.addEventListener(eventName, eventListener);

        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);
};

// Конвертировать rgb/rgba строку в hex
export const convertRgbToHex = rgba => {
    if (rgba[0] !== '#') {
        const getMiddle = rgba.split('(')[1].split(')')[0];
        const values = getMiddle.split(',');

        if (values.length === 3) {
            return `#${values
                .map(v => {
                    v = parseInt(v).toString(16);
                    return v.length === 1 ? '0' + v : v;
                })
                .join('')}`;
        } else {
            if (parseFloat(values[3]) === 1) {
                return `#${values
                    .slice(0, 3)
                    .map(v => {
                        v = parseInt(v).toString(16);
                        return v.length === 1 ? '0' + v : v;
                    })
                    .join('')}`;
            } else {
                return `rgba(${parseInt(values[0])}, ${parseInt(values[1])}, ${parseInt(values[2])}, ${parseFloat(
                    values[3],
                )})`;
            }
        }
    } else {
        return rgba;
    }
};

// Конвертировать rgb/rgba строку в hex и alpha
export const convertRgbaToHexAndAlpha = rgba => {
    if (rgba[0] !== '#') {
        const getMiddle = rgba.split('(')[1].split(')')[0];
        const values = getMiddle.split(',');

        if (values.length === 3) {
            return `#${values
                .map(v => {
                    v = parseInt(v).toString(16);
                    return v.length === 1 ? '0' + v : v;
                })
                .join('')}`;
        } else {
            if (parseFloat(values[3]) === 1) {
                return `#${values
                    .slice(0, 3)
                    .map(v => {
                        v = parseInt(v).toString(16);
                        return v.length === 1 ? '0' + v : v;
                    })
                    .join('')}`;
            } else {
                return `#${values
                    .slice(0, 3)
                    .map(v => {
                        v = parseInt(v).toString(16);
                        return v.length === 1 ? '0' + v : v;
                    })
                    .join('')}, ${values[3]}`;
            }
        }
    } else {
        return rgba;
    }
};

// Проверяем вписывается ли компонент на экране
export const isOverlapBottom = (element, checkHeight) => {
    const bottom = element.getBoundingClientRect().bottom;
    const height = window.innerHeight;
    const delta = height - bottom;

    return delta < checkHeight;
};

/**
 * Creates a read/writable property which returns a function set for write/set (assignment)
 * and read/get access on a variable
 *
 * @param {Any} value initial value of the property
 */
function createProperty(value) {
    let _value = value;

    /**
     * Overwrite getter.
     *
     * @returns {Any} The Value.
     * @private
     */
    function _get() {
        return _value;
    }

    /**
     * Overwrite setter.
     *
     * @param {Any} v   Sets the value.
     * @private
     */
    function _set(v) {
        _value = v;
    }

    return {
        get: _get,
        set: _set,
    };
}

/**
 * Creates or replaces a read-write-property in a given scope object, especially for non-writable properties.
 * This also works for built-in host objects (non-DOM objects), e.g. navigator.
 * Optional an initial value can be passed, otherwise the current value of the object-property will be set.
 *
 * @param {Object} objBase  e.g. window
 * @param {String} objScopeName    e.g. "navigator"
 * @param {String} propName    e.g. "userAgent"
 * @param {Any} initValue (optional)   e.g. window.navigator.userAgent
 */
export function makePropertyWritable(objBase, objScopeName, propName, initValue) {
    let newProp, initObj;

    if (objBase && objScopeName in objBase && propName in objBase[objScopeName]) {
        if (typeof initValue === 'undefined') {
            initValue = objBase[objScopeName][propName];
        }

        newProp = createProperty(initValue);

        try {
            Object.defineProperty(objBase[objScopeName], propName, newProp);
        } catch (e) {
            initObj = {};
            initObj[propName] = newProp;
            try {
                objBase[objScopeName] = Object.create(objBase[objScopeName], initObj);
            } catch (e) {
                // Workaround, but necessary to overwrite native host objects
            }
        }
    }
}

// Картинку в base64
export const toBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

export const wordPad = (num, t, ta, tov) => {
    num = num % 100;
    if (num % 10 === 1 && (num < 10 || num > 20)) return t;
    else if (num % 10 >= 2 && num % 10 <= 4 && (num < 10 || num > 20)) return ta;
    else return tov;
};

export const numberWithSpaces = x => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const renderErrors = (errorName, errors, defaultError = ERROR_UNKNOWN) => {
    let errorText = defaultError;
    Object.keys(errors).forEach(error => {
        if (error === errorName) {
            errorText = errors[error];
        }
    });

    return errorText;
};

/**
 * Открыть ссылку
 * @param {string} link - Ссылка.
 * @param {("_blank" | "_self" | "_parent" | "_top")} target - Место открытия ссылки.
 * @return {string}
 */
export const openLink = (link, target = '_self') => {
    const a = document.createElement('a');
    a.href = link;
    a.target = target;

    a.dispatchEvent(
        new window.MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        }),
    );
};
