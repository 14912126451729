import React from 'react';
import { useSelector } from 'react-redux';
import ComponentsListItem from './ComponentsListItem';
import Head from './Head';

const ComponentsList = () => {
    const components = useSelector(state => state.editor.panels[state.editor.currentPanel].components);

    const renderList = () =>
        components.map((c, i) => <ComponentsListItem key={i} title={c.title} icon={c.icon} num={i} />);

    return (
        <>
            <Head title="Список компонентов" />
            <div className="components-list">{renderList()}</div>
        </>
    );
};

export default ComponentsList;
