export const EDITOR_ADD_COMPONENT = 'EDITOR_ADD_COMPONENT';
export const EDITOR_LOAD_COMPONENT = 'EDITOR_LOAD_COMPONENT';
export const EDITOR_ADD_PANEL = 'EDITOR_ADD_PANEL';
export const EDITOR_LOAD_PANEL = 'EDITOR_LOAD_PANEL';
export const EDITOR_SELECT_PANEL = 'EDITOR_SELECT_PANEL';
export const EDITOR_SET_STARTUP_PANEL = 'EDITOR_SET_STARTUP_PANEL';
export const EDITOR_SET_PANEL_TITLE = 'EDITOR_SET_PANEL_TITLE';
export const EDITOR_DELETE_PANEL = 'EDITOR_DELETE_PANEL';
export const EDITOR_SET_IS_DRAGGING = 'EDITOR_SET_IS_DRAGGING';
export const EDITOR_SET_DRAG_POSITION = 'EDITOR_SET_DRAG_POSITION';
export const EDITOR_SET_DRAG_OFFSET = 'EDITOR_SET_DRAG_OFFSET';
export const EDITOR_RESET_DRAGGING = 'EDITOR_RESET_DRAGGING';
export const EDITOR_SET_IS_ON_DRAGGING_ZONE = 'EDITOR_SET_IS_ON_DRAGGING_ZONE';
export const EDITOR_SET_HOVER_COMPONENT_NUM = 'EDITOR_SET_HOVER_COMPONENT_NUM';
export const EDITOR_SET_HOVER_COMPONENT_OVER_TOP = 'EDITOR_SET_HOVER_COMPONENT_OVER_TOP';
export const EDITOR_SET_DRAG_COMPONENT_TYPE = 'EDITOR_SET_DRAG_COMPONENT_TYPE';
export const EDITOR_SET_DRAG_COMPONENT_OPTIONS = 'EDITOR_SET_DRAG_COMPONENT_OPTIONS';
export const EDITOR_SET_IS_ON_MIDDLE_ZONE = 'EDITOR_SET_IS_ON_MIDDLE_ZONE';
export const EDITOR_SET_CONSTRUCTOR_VIEWER_SIZE = 'EDITOR_SET_CONSTRUCTOR_VIEWER_SIZE';
export const EDITOR_COMPONENT_DOWN = 'EDITOR_COMPONENT_DOWN';
export const EDITOR_COMPONENT_UP = 'EDITOR_COMPONENT_UP';
export const EDITOR_COMPONENT_COPY = 'EDITOR_COMPONENT_COPY';
export const EDITOR_COMPONENT_DELETE = 'EDITOR_COMPONENT_DELETE';
export const EDITOR_COMPONENT_PREVIEW = 'EDITOR_COMPONENT_PREVIEW';
export const EDITOR_SELECT_COMPONENT = 'EDITOR_SELECT_COMPONENT';
export const EDITOR_SET_COMPONENT_DATA = 'EDITOR_SET_COMPONENT_DATA';
export const EDITOR_SET_IS_DARK_THEME = 'EDITOR_SET_IS_DARK_THEME';
export const EDITOR_SET_IS_IOS = 'EDITOR_SET_IS_IOS';
export const EDITOR_SET_PHONE_SCREEN = 'EDITOR_SET_PHONE_SCREEN';
export const EDITOR_SET_PROJECT_KEY = 'EDITOR_SET_PROJECT_KEY';
export const EDITOR_RESET_PANELS_AND_PROJECT = 'EDITOR_RESET_PANELS_AND_PROJECT';
export const EDITOR_REMOVE_ZERO_PANEL = 'EDITOR_REMOVE_ZERO_PANEL';
