import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomScroll from 'react-custom-scroll';
import '../../styles/popup-menu.scss';
import '../../styles/custom-scroll.scss';

const PopupMenu = ({
    items,
    itemsClickFunc,
    position = 'center',
    title,
    maxElementsShow,
    isOpenedDefault,
    defaultWidth = 'auto',
    icon,
}) => {
    const [isOpened, setIsOpened] = useState(isOpenedDefault);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = event => {
            if (isOpened) {
                if (wrapperRef && !wrapperRef.current.contains(event.target)) {
                    setIsOpened(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpened]);

    const calcMaxHeight = () => {
        if (items.length > maxElementsShow) {
            return { height: `${maxElementsShow * 45 + 22}px` };
        } else {
            return { height: 'auto' };
        }
    };

    const openOrClose = () => {
        setIsOpened(!isOpened);
    };

    const renderItems = () =>
        items.map((item, i) => (
            <div className="popup-menu__item" onClick={e => itemsClickFunc[i](e, setIsOpened)} key={i}>
                {item}
            </div>
        ));

    return (
        <div
            className={`popup-menu popup-menu_position_${position}${isOpened ? ' popup-menu_opened' : ''}`}
            style={calcMaxHeight()}
            ref={wrapperRef}
        >
            <div className="popup-menu__title" onClick={openOrClose}>
                {icon && (
                    <div
                        className="popup-menu__icon"
                        style={{ maskImage: `url(${icon})`, WebkitMaskImage: `url(${icon})` }}
                    />
                )}
                <div className="popup-menu__text">{title}</div>
                <div className="popup-menu__chevron" />
            </div>
            <CustomScroll heightRelativeToParent="100%">
                <div
                    className="popup-menu__items"
                    style={{ width: defaultWidth === 'auto' ? defaultWidth : `${defaultWidth}px` }}
                >
                    {renderItems()}
                </div>
            </CustomScroll>
        </div>
    );
};

PopupMenu.propTypes = {
    items: PropTypes.array,
    itemsClickFunc: PropTypes.array,
    position: PropTypes.string,
    maxElementsShow: PropTypes.number,
    isOpenedDefault: PropTypes.bool,
    icon: PropTypes.string,
};

export default PopupMenu;
