import * as types from './actionTypes';

export const appsSetApps = apps => ({
    type: types.APPS_SET_APPS,
    apps,
});

export const appsNewApp = newApp => ({
    type: types.APPS_NEW_APP,
    newApp,
});

export const appsDeleteApp = appId => ({
    type: types.APPS_DELETE_APP,
    appId,
});

export const appsSetAppSettings = appSettings => ({
    type: types.APPS_SET_APP_SETTINGS,
    appSettings,
});
