import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { IOS_PHONE_SCREEN_SIZES } from '../services/locals';
import {
    editorResetPanelsAndProject,
    editorSelectComponent,
    editorSetConstructorViewerSize,
    editorSetIsDarkTheme,
    editorSetIsIOS,
    editorSetPhoneScreen,
} from '../store/editor/actions';
import { checkAuth } from '../services/auth';
import { loadApp, saveApp, updateAppDefaultLink } from '../services/axios';
import {
    globalsSetIsLoadingSomething,
    globalsSetIsProjectLoading,
    globalsSetModal,
    globalsSetProjectTimeout,
    globalsSetVkAppId,
} from '../store/globals/actions';
import { renderErrors } from '../services/functions';
import DraggingComponentsList from '../components/DraggingComponentsList';
import DraggingComponent from '../components/DraggingComponent';
import ConstructorViewer from '../components/ConstructorViewer';
import HeaderMenu from '../components/HeaderMenu';
import Head from '../components/Head';
import Switch from '../components/Switch';
import Dropdown from '../components/Dropdown';
import EmptyBlock from '../components/EmptyBlock';
import ComponentsList from '../components/ComponentsList';
import ComponentSettingsList from '../components/ComponentSettingsList';
import MiniHead from '../components/MiniHead';
import ProjectJSONLoaderAndSaver from '../classes/ProjectJSONLoaderAndSaver';
import ModalError from '../components/ModalError';
import iconMoon from '../../img/icons/moon.svg';
import iconSun from '../../img/icons/sun.svg';
import iconBackground from '../../img/icons/background.svg';
import '../../styles/page-editor.scss';

const PageEditor = () => {
    const dispatch = useDispatch();
    const components = useSelector(state => state.editor.panels[state.editor.currentPanel].components);
    const dragPosition = useSelector(state => state.editor.dragPosition);
    const isDragging = useSelector(state => state.editor.isDragging);
    const dragOptions = useSelector(state => state.editor.dragOptions);
    const selectedPhone = useSelector(state => state.editor.selectedPhone);
    const constructorViewerSize = useSelector(state => state.editor.constructorViewerSize);
    const selectedComponentNum = useSelector(state => state.editor.selectedComponentNum);
    const isDarkTheme = useSelector(state => state.editor.isDarkTheme);
    const isIOS = useSelector(state => state.editor.isIOS);
    const phoneScreen = useSelector(state => state.editor.phoneScreen);
    const projectKey = useSelector(state => state.editor.projectKey);
    const pageNum = useSelector(state => state.globals.pageNum);
    const [osUpdateKey, setOsUpdateKey] = useState(0);
    const [viewerOffset, setViewerOffset] = useState(0);
    const lastScrollPosition = useRef(0);
    const scrollPositionTotalDelta = useRef(0);

    useEffect(() => {
        (async () => {
            dispatch(globalsSetIsLoadingSomething(true));
            dispatch(globalsSetIsProjectLoading(true));

            await checkAuth();
            const loadResult = await loadApp(pageNum);
            if (loadResult.result === 'success') {
                ProjectJSONLoaderAndSaver().loadProject(dispatch, loadResult.app_json);
                dispatch(globalsSetVkAppId(+loadResult.vk_add_id));
            } else {
                dispatch(push('/'));
                const errorText = renderErrors(loadResult.error, {
                    app_does_not_exist: ['Ошибка загрузки приложения.', 'Приложение не существует или удалено.'],
                    app_json_does_not_exist: ['Ошибка загрузки приложения.', 'Файл проекта не существует или удален.'],
                });
                dispatch(globalsSetModal(<ModalError description={errorText} />));
            }

            dispatch(globalsSetIsLoadingSomething(false));
            dispatch(globalsSetIsProjectLoading(false));
        })();

        setViewSize(phoneScreen);

        window.addEventListener('scroll', scrollPage);

        return () => {
            dispatch(globalsSetProjectTimeout(null));

            const jsonToSave = JSON.stringify(ProjectJSONLoaderAndSaver().generate());
            saveApp(pageNum, jsonToSave);

            dispatch(editorResetPanelsAndProject());
            window.removeEventListener('scroll', scrollPage);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', scrollPage);
        window.addEventListener('resize', scrollPage);

        return () => {
            window.removeEventListener('scroll', scrollPage);
            window.removeEventListener('resize', scrollPage);
        };
    }, [constructorViewerSize]);

    useEffect(() => {
        setViewSize(phoneScreen);
    }, [phoneScreen]);

    useEffect(() => {
        if (!isIOS) {
            window.navigator.userAgent =
                'Mozilla/5.0 (Linux; Android 8.0.0; SM-G930F Build/R16NW; wv) AppleWebKit/537.36 (KHTML, like Gecko) Version/4.0 Chrome/74.0.3729.157 Mobile Safari/537.36';
        } else {
            window.navigator.userAgent =
                'Mozilla/5.0 (iPhone; CPU iPhone OS 12_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148';
        }

        setOsUpdateKey(Math.random());
    }, [isIOS]);

    const scrollPage = () => {
        const bodyHeight = document.body.offsetHeight;
        const containerHeight = constructorViewerSize[1];
        const scroll = window.scrollY;
        const scrollDelta = window.scrollY - lastScrollPosition.current;
        const middlePaddingTop = parseInt(
            window
                .getComputedStyle(document.querySelector('.page-editor__middle'), null)
                .getPropertyValue('padding-top'),
        );
        const middlePaddingBottom = parseInt(
            window
                .getComputedStyle(document.querySelector('.page-editor__middle'), null)
                .getPropertyValue('padding-bottom'),
        );
        const topMenuHeight = document.querySelector('.header-menu').offsetHeight;
        const topOffset = middlePaddingTop + topMenuHeight;
        const bottomOffset =
            containerHeight + topOffset + middlePaddingBottom < bodyHeight
                ? bodyHeight - containerHeight - topOffset
                : middlePaddingBottom;
        const totalHeightDelta = bodyHeight - containerHeight - middlePaddingTop - middlePaddingBottom;

        scrollPositionTotalDelta.current += scrollDelta * 2;
        if (scrollPositionTotalDelta.current >= 0) {
            scrollPositionTotalDelta.current = 0;
        }

        if (scrollPositionTotalDelta.current <= totalHeightDelta) {
            scrollPositionTotalDelta.current = totalHeightDelta;
        }

        let newTopOffset =
            containerHeight - bodyHeight - scroll + topOffset + bottomOffset + scrollPositionTotalDelta.current;
        if (newTopOffset < 0) {
            newTopOffset = Math.abs(newTopOffset);
        } else {
            newTopOffset = 0;
        }

        lastScrollPosition.current = scroll;
        setViewerOffset(newTopOffset);
    };

    const prepareIOSPhones = () => {
        const phones = {};
        IOS_PHONE_SCREEN_SIZES.forEach(s => (phones[s.name] = s.title));

        return phones;
    };

    const setTheme = theme => {
        if (theme === 'dark') {
            dispatch(editorSetIsDarkTheme(true));
        } else {
            dispatch(editorSetIsDarkTheme(false));
        }
    };

    const setOs = os => {
        if (os === 'android') {
            dispatch(editorSetIsIOS(false));
        } else {
            dispatch(editorSetIsIOS(true));
        }
    };

    const setViewSize = name => {
        if (name !== selectedPhone) {
            IOS_PHONE_SCREEN_SIZES.forEach(s => {
                if (s.name === name) {
                    dispatch(editorSetConstructorViewerSize(name, [s.width, s.height]));
                }
            });
            dispatch(editorSetPhoneScreen(name));
        }
    };

    const deselectComponent = e => {
        if (e.target.classList.contains('page-editor__middle')) {
            dispatch(editorSelectComponent(-1));
        }
    };

    return (
        <div className="page-editor" key={projectKey}>
            <div onClick={() => updateAppDefaultLink(pageNum)}>asdhausdiasduh</div>
            <HeaderMenu />
            <div className="page-editor__content">
                <div className="page-editor__left-side">
                    {selectedComponentNum !== -1 && <ComponentSettingsList />}
                    {selectedComponentNum === -1 && components.length !== 0 && <ComponentsList />}
                    {selectedComponentNum === -1 && components.length === 0 && (
                        <EmptyBlock
                            title="Компоненты отсутствуют"
                            desc="Добавляйте компоненты на экран, перетаскивая их из каталога компонентов"
                            icon={iconBackground}
                            style={{
                                minHeight: 'calc(100vh - 108px)',
                                width: '260px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        />
                    )}
                </div>
                <div
                    className="page-editor__middle"
                    style={{ minWidth: `${constructorViewerSize[0] + 100}px` }}
                    onClick={deselectComponent}
                >
                    <ConstructorViewer
                        key={osUpdateKey}
                        width={constructorViewerSize[0]}
                        height={constructorViewerSize[1]}
                        offset={viewerOffset}
                    />
                </div>
                <div className="page-editor__right-side">
                    <Head title="Параметры просмотра" />
                    <MiniHead title="Операционная система" />
                    <Switch
                        options={['iOS', 'Android']}
                        values={['ios', 'android']}
                        width={180}
                        defaultValue={isIOS ? 'ios' : 'android'}
                        changeFunc={setOs}
                    />
                    <MiniHead title="Тема приложения" />
                    <Switch
                        icons={[iconSun, iconMoon]}
                        values={['light', 'dark']}
                        defaultValue={isDarkTheme ? 'dark' : 'light'}
                        changeFunc={setTheme}
                    />
                    <MiniHead title="Размер экрана" />
                    <Dropdown
                        options={prepareIOSPhones()}
                        setCurrentValue={phoneScreen}
                        maxOptionsShow={5}
                        changeFunc={setViewSize}
                    />
                    <Head title="Компоненты" />
                    <DraggingComponentsList />
                </div>
                <DraggingComponent
                    isDrag
                    isDragging={isDragging}
                    dragX={dragPosition.x}
                    dragY={dragPosition.y}
                    title={dragOptions.title}
                    images={dragOptions.images}
                />
            </div>
        </div>
    );
};

export default PageEditor;
