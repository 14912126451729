import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DRAG_ZONE_HEIGHT } from '../services/locals';
import { COMPONENTS_LIST } from '../constants/components/components-list';
import {
    editorAddComponent,
    editorResetDragging,
    editorSetDragPosition,
    editorSetHoverComponentNum,
    editorSetHoverComponentOverTop,
    editorSetIsDragging,
    editorSetIsOnDraggingZone,
    editorSetIsOnMiddleZone,
} from '../store/editor/actions';
import { useEventListener } from '../services/functions';
import EditorComponents from '../classes/EditorComponents';
import DraggingComponent from './DraggingComponent';
import Collapse from './Collapse';

const DraggingComponentsList = () => {
    const dispatch = useDispatch();
    const isDragging = useSelector(state => state.editor.isDragging);
    const isOnMiddleZone = useSelector(state => state.editor.isOnMiddleZone);
    const dragOffset = useSelector(state => state.editor.dragOffset);
    const dragComponentType = useSelector(state => state.editor.dragComponentType);
    const dragOptions = useSelector(state => state.editor.dragOptions);
    const components = useSelector(state => state.editor.panels[state.editor.currentPanel].components);
    const hasHeader = useSelector(state => state.editor.panels[state.editor.currentPanel].hasHeader);

    const dragMove = e => {
        if (isDragging) {
            dispatch(editorSetDragPosition(e.pageX - dragOffset.x, e.pageY - dragOffset.y));
            checkZone(e);
        }
    };

    const dragEnd = e => {
        if (isDragging) {
            document.body.classList.remove('dragging');

            dispatch(editorSetIsDragging(false));

            if (isOnMiddleZone) {
                dispatch(
                    editorAddComponent({
                        componentName: EditorComponents().getComponentName(dragComponentType),
                        component: EditorComponents().getComponent(dragComponentType),
                        title: dragOptions.title,
                        icon: dragOptions.icon,
                        data: EditorComponents().getComponentDefaultData(dragComponentType),
                        name: dragComponentType,
                    }),
                );
                dispatch(editorSetIsOnDraggingZone(false));
            }

            dispatch(editorSetIsOnMiddleZone(false));

            dispatch(editorResetDragging());
        }
    };

    const checkZone = e => {
        const elements = document.elementsFromPoint(e.clientX, e.clientY);
        const cvTopOffset = document.querySelector('.constructor-viewer').getBoundingClientRect().top;
        const scrollContainer = document.querySelector('.rcs-inner-container');
        const scrollTop = scrollContainer.scrollTop;
        const scrollHeight = scrollContainer.offsetHeight;
        const screenHeight = document.querySelector('.Panel__in').offsetHeight;

        // Чекаем над конкретными компонентами
        let isFound = false;
        let isOnMiddle = false;
        let isOnDraggingZoneCheck = false;
        let numComponent = -1;
        elements.forEach(el => {
            if (!isOnMiddle && el.classList.contains('page-editor__middle')) {
                dispatch(editorSetIsOnMiddleZone(true));
                isOnMiddle = true;
            }

            if (!isFound && el.classList.contains('constructor-component')) {
                numComponent = +el.getAttribute('num');
                if (el.getBoundingClientRect().top + el.offsetHeight / 2 - DRAG_ZONE_HEIGHT / 2 > e.clientY) {
                    if (numComponent === 0 && hasHeader) {
                        dispatch(editorSetHoverComponentOverTop(false));
                    } else {
                        dispatch(editorSetHoverComponentOverTop(true));
                    }
                } else {
                    dispatch(editorSetHoverComponentOverTop(false));
                }
                dispatch(editorSetHoverComponentNum(numComponent));
                isFound = true;
            }
        });

        // Чекаем если не над компонентом, а сверху или снизу зоны
        let num = 0;
        if (!isFound) {
            elements.forEach(el => {
                // Если сверху
                if (!isFound && el.classList.contains('page-editor__middle') && e.clientY < cvTopOffset) {
                    dispatch(editorSetHoverComponentOverTop(!hasHeader));
                    dispatch(editorSetHoverComponentNum(0));
                    isFound = true;
                }

                // Если снизу
                if (!isFound && el.classList.contains('page-editor__middle')) {
                    dispatch(editorSetHoverComponentOverTop(false));
                    dispatch(editorSetHoverComponentNum(components.length - 1));
                    isFound = true;
                }

                // Если вне зоны дропа
                if (!isOnMiddle && !el.classList.contains('page-editor__middle') && num === elements.length - 1) {
                    dispatch(editorSetHoverComponentNum(-1));
                    dispatch(editorSetIsOnMiddleZone(false));
                }

                num++;
            });
        }

        if (scrollTop === screenHeight - scrollHeight) {
            scrollContainer.scrollTop = 100000000000;
            setTimeout(() => (scrollContainer.scrollTop = 100000000000), 1);
        }

        if (numComponent !== -1 && numComponent === components.length - 1) {
            setTimeout(() => (scrollContainer.scrollTop = 100000000000), 1);
        }

        return isOnDraggingZoneCheck;
    };

    useEventListener('mouseup', dragEnd);
    useEventListener('mousemove', dragMove);

    const renderList = () => {
        const renderedList = [];
        COMPONENTS_LIST.map(list => {
            const listComponents = [];
            list.components.forEach(c => {
                listComponents.push([
                    <DraggingComponent title={c.title} icon={list.icon} type={c.name} key={c.name} images={c.images} />,
                ]);
            });
            renderedList.push(
                <Collapse title={list.title} icon={list.icon} key={list.title}>
                    <div className="draggable-component-list">{listComponents}</div>
                </Collapse>,
            );
        });

        return renderedList;
    };

    return renderList();
};

export default DraggingComponentsList;
