import { ICON_IMAGE, ICON_PADDING } from '../../icons';

export const CONTAINER_SETTINGS = {
    container: {
        properties: [
            {
                headTitle: 'Параметры блока',
                title: 'Фон',
                icon: ICON_IMAGE,
                settings: [
                    {
                        type: 'input-color',
                        title: 'Цвет',
                        size: '1-2',
                        structLight: 'style.container.bright_light.backgroundColor',
                        structDark: 'style.container.space_gray.backgroundColor',
                        defaultValueLight: '#ffffff',
                        defaultValueDark: '#19191a',
                    },
                    {
                        type: 'input-image',
                        title: 'Фоновое изображение',
                        size: '1-1',
                        structLight: 'style.container.bright_light.backgroundImage',
                        structDark: 'style.container.space_gray.backgroundImage',
                        defaultValueLight: '',
                        defaultValueDark: '',
                        bottomTitle: 'Оптимальный размер изображения 111х222px',
                    },
                    {
                        type: 'select',
                        title: 'Размер',
                        size: '1-1',
                        structLight: 'style.container.bright_light.backgroundSize',
                        structDark: 'style.container.space_gray.backgroundSize',
                        defaultValue: 'cover',
                        selectValues: {
                            cover: 'На весь блок',
                            contain: 'Внутри блока',
                            '100% 100%': 'Растянуть',
                            auto: 'Реальный размер',
                        },
                        hideWhen: [
                            {
                                struct: [
                                    'style.container.bright_light.backgroundImage',
                                    'style.container.space_gray.backgroundImage',
                                ],
                                checkValues: [''],
                            },
                        ],
                    },
                    {
                        type: 'default',
                        structLight: 'style.container.bright_light.backgroundPosition',
                        structDark: 'style.container.space_gray.backgroundPosition',
                        defaultValue: 'center',
                    },
                    {
                        type: 'default',
                        structLight: 'style.container.bright_light.backgroundRepeat',
                        structDark: 'style.container.space_gray.backgroundRepeat',
                        defaultValue: 'no-repeat',
                    },
                ],
            },
            {
                title: 'Отступы',
                icon: ICON_PADDING,
                settings: [
                    {
                        type: 'input-size',
                        title: 'Сверху',
                        size: '1-2',
                        structLight: 'style.container.bright_light.paddingTop',
                        structDark: 'style.container.space_gray.paddingTop',
                        defaultValue: '12px',
                        isOneProperty: true,
                    },
                    {
                        type: 'input-size',
                        title: 'Снизу',
                        size: '1-2',
                        structLight: 'style.container.bright_light.paddingBottom',
                        structDark: 'style.container.space_gray.paddingBottom',
                        defaultValue: '12px',
                        isOneProperty: true,
                    },
                    {
                        type: 'input-size',
                        title: 'Слева',
                        size: '1-2',
                        structLight: 'style.container.bright_light.paddingLeft',
                        structDark: 'style.container.space_gray.paddingLeft',
                        defaultValue: '12px',
                        isOneProperty: true,
                    },
                    {
                        type: 'input-size',
                        title: 'Справа',
                        size: '1-2',
                        structLight: 'style.container.bright_light.paddingRight',
                        structDark: 'style.container.space_gray.paddingRight',
                        defaultValue: '12px',
                        isOneProperty: true,
                    },
                ],
            },
        ],
    },
};
