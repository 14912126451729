import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isOverlapBottom } from '../services/functions';
import CustomScroll from 'react-custom-scroll';
import '../../styles/dropdown.scss';
import '../../styles/custom-scroll.scss';

const Dropdown = ({ options, defaultValue, value, changeFunc, maxOptionsShow, placeholder, setCurrentValue }) => {
    const [selected, setSelected] = useState(defaultValue);
    const [isOpened, setIsOpened] = useState(false);
    const [isTop, setIsTop] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        if (setCurrentValue !== undefined) {
            setSelected(setCurrentValue);
        }
    }, [setCurrentValue]);

    useEffect(() => {
        if (value !== undefined) {
            setSelected(value);
        }
    }, [value]);

    useEffect(() => {
        const handleClickOutside = event => {
            if (isOpened) {
                if (wrapperRef && !wrapperRef.current.contains(event.target)) {
                    setIsOpened(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpened]);

    const openList = () => {
        let maxHeight;
        if (maxOptionsShow !== undefined && Object.keys(options).length > maxOptionsShow) {
            maxHeight = maxOptionsShow * 45 + 10;
        } else {
            maxHeight = Object.keys(options).length * 45 + 10;
        }

        setIsTop(isOverlapBottom(wrapperRef.current, maxHeight));
        setIsOpened(!isOpened);
    };

    const selectOption = option => {
        setSelected(option);
        setIsOpened(false);

        if (changeFunc !== undefined) {
            changeFunc(option);
        }
    };

    const calcMaxHeight = () => {
        if (maxOptionsShow !== undefined && Object.keys(options).length > maxOptionsShow) {
            return { height: `${maxOptionsShow * 45 + 22}px` };
        } else {
            return { height: 'auto' };
        }
    };

    const renderOptionSelectedText = () => {
        if (selected === undefined || options[selected] === undefined) {
            return placeholder !== undefined ? placeholder : 'Не выбрано';
        } else {
            return options[selected];
        }
    };

    const renderSelected = () => (
        <div className="dropdown__selected" onClick={openList}>
            <div className={`dropdown__selected-text${selected === undefined ? ' dropdown__selected-text_light' : ''}`}>
                {renderOptionSelectedText()}
            </div>
            <div className="dropdown__selected-icon" />
        </div>
    );

    const renderOptions = () =>
        Object.keys(options).map(oKey => (
            <div className="dropdown__option" onClick={() => selectOption(oKey)} key={oKey}>
                {options[oKey]}
            </div>
        ));

    return (
        <div
            className={`dropdown ${isOpened ? ' dropdown_opened' : ''}${isTop ? ' dropdown_is-top' : ''}`}
            ref={wrapperRef}
        >
            <div className="dropdown__container">
                {renderSelected()}
                <div className="dropdown__options" style={calcMaxHeight()}>
                    <CustomScroll heightRelativeToParent="100%">{renderOptions()}</CustomScroll>
                </div>
            </div>
        </div>
    );
};

Dropdown.propTypes = {
    options: PropTypes.object,
    defaultValue: PropTypes.string,
    changeFunc: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    maxOptionsShow: PropTypes.number,
    setCurrentValue: PropTypes.string,
};

export default Dropdown;
