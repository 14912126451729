import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/ios-switch.scss';

const IosSwitch = ({ changeFunc, defaultValue, value }) => {
    const [isSwitchOn, setIsSwitchOn] = useState(defaultValue);

    useEffect(() => {
        if (value !== undefined) {
            setIsSwitchOn(value);
        }
    }, [value]);

    const switchAction = () => {
        const newSwitch = !isSwitchOn;
        if (value === undefined) {
            setIsSwitchOn(newSwitch);
        }

        changeFunc(newSwitch);
    };

    return (
        <div className={`ios-switch${isSwitchOn ? ' ios-switch_is-on' : ''}`} onClick={switchAction}>
            <div className="ios-switch__dot" />
        </div>
    );
};

IosSwitch.propTypes = {
    changeFunc: PropTypes.func,
    defaultValue: PropTypes.bool,
    value: PropTypes.bool,
};

export default IosSwitch;
