import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { globals } from './globals/reducers';
import { editor } from './editor/reducers';
import { auth } from './auth/reducers';
import { apps } from './apps/reducers';

export const rootReducer = combineReducers({
    router: routerReducer,
    globals,
    editor,
    auth,
    apps,
});
