import * as types from './actionTypes';

const initialState = {
    vkIsConnected: null,
    firstAuthChecked: false,
    settingsLoaded: false,
    prevPage: '',
    prevPageParams: [],
};

export const auth = (state = initialState, action) => {
    switch (action.type) {
        case types.AUTH_SET_VK_IS_CONNECTED:
            return {
                ...state,
                vkIsConnected: action.vkIsConnected,
            };

        case types.AUTH_SET_FIRST_AUTH_CHECKED:
            return {
                ...state,
                firstAuthChecked: action.firstAuthChecked,
            };

        case types.AUTH_SET_PREV_PAGE:
            return {
                ...state,
                prevPage: action.prevPage,
                prevPageParams: action.prevPageParams,
            };

        case types.AUTH_SET_SETTINGS_LOADED:
            return {
                ...state,
                settingsLoaded: action.settingsLoaded,
            };

        default:
            return state;
    }
};
