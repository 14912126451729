import { ICON_IMAGE, ICON_PADDING } from '../../icons';

export const COMPONENT_IMAGE = {
    image: {
        componentName: 'MCImage',
        properties: [
            {
                title: 'Изображение',
                icon: ICON_IMAGE,
                settings: [
                    {
                        type: 'input-text',
                        title: 'Ссылка на картинку',
                        size: '1-1',
                        structLight: 'data.image.src',
                        structDark: 'data.image.src',
                        placeholder: '',
                        defaultValue: '',
                    },
                    {
                        type: 'select',
                        title: 'Расположение',
                        size: '1-1',
                        structLight: 'style.container.bright_light.textAlign',
                        structDark: 'style.container.space_gray.textAlign',
                        defaultValue: 'left',
                        selectValues: {
                            center: 'По центру',
                            right: 'Справа',
                            left: 'Слева',
                        },
                    },
                ],
            },
            {
                title: 'Размер',
                icon: ICON_PADDING,
                settings: [
                    {
                        type: 'input-size',
                        title: 'Ширина',
                        size: '1-2',
                        structLight: 'data.image.width',
                        structDark: 'data.image.width',
                        defaultValue: '100%',
                    },
                    {
                        type: 'input-size',
                        title: 'Высота',
                        size: '1-2',
                        structLight: 'data.image.height',
                        structDark: 'data.image.height',
                        defaultValue: 'auto',
                    },
                ],
            },
        ],
    },
};
