import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    DEFAULT_APP_DESCRIPTION,
    DEFAULT_APP_TITLE,
    DEFAULT_APP_TYPE,
    ERROR_UNKNOWN,
    VK_APP_TYPES,
} from '../services/locals';
import { globalsCloseModal, globalsSetModal } from '../store/globals/actions';
import { appsSetAppSettings } from '../store/apps/actions';
import { getAppSettings, updateApp } from '../services/axios';
import Modal from './Modal';
import Button from './Button';
import Tab from './Tab';
import InputText from './InputText';
import MiniHead from './MiniHead';
import TextareaText from './TextareaText';
import Dropdown from './Dropdown';
import UploadScreenshots from './UploadScreenshots';
import ModalError from './ModalError';
import { renderErrors } from '../services/functions';

const ModalSettings = () => {
    const dispatch = useDispatch();
    const appSettings = useSelector(state => state.apps.appSettings);
    const appId = useSelector(state => state.globals.pageNum);
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [shortDesc, setShortDesc] = useState('');
    const [type, setType] = useState(DEFAULT_APP_TYPE);
    const [visibility, setVisibility] = useState('');
    const [nameError, setNameError] = useState('');
    const [shortDescError, setShortDescError] = useState('');
    const [descError, setDescError] = useState('');
    const [isSettingsLoaded, setIsSettingsLoaded] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const defaultTitle = DEFAULT_APP_TITLE;
    const defaultDesc = DEFAULT_APP_DESCRIPTION;

    useEffect(() => {
        (async () => {
            const getSettingsResult = await getAppSettings(appId);

            if (getSettingsResult.result === 'fail') {
                dispatch(globalsCloseModal());
                const errorText = renderErrors(getSettingsResult.error, {
                    app_does_not_exist: ['Ошибка загрузки настроек.', 'Приложение не существует или удалено.'],
                });
                dispatch(globalsSetModal(<ModalError description={errorText} />));
            } else {
                dispatch(appsSetAppSettings(getSettingsResult.app));
                setName(getSettingsResult.app.title);
                setDesc(getSettingsResult.app.description);
                setShortDesc(getSettingsResult.app.short_desc);
                setType(getSettingsResult.app.app_type);
                setVisibility(!!+getSettingsResult.app.visibility ? 'on' : 'off');
            }

            setIsSettingsLoaded(true);
        })();
    }, []);

    const closeModal = () => {
        dispatch(globalsCloseModal());
        resetAppSettings();
    };

    const resetAppSettings = () => {
        dispatch(appsSetAppSettings({}));
    };

    const saveApp = async () => {
        if (checkErrors()) {
            setCurrentTab(0);
            return;
        }

        closeModal();

        const updateResult = await updateApp(
            appId,
            name === '' ? defaultTitle : name,
            desc === '' ? defaultDesc : desc,
            shortDesc,
            type,
            visibility === 'on' ? 1 : 0,
        );

        if (updateResult.result === 'fail') {
            const errorText = renderErrors(updateResult.error, {
                vk_app_does_not_exist: 'Приложение не существует.',
                cant_update_vk_app: ['Не удалось сохранить изменения.', 'Пожалуйста, повторите попытку позже.'],
            });
            dispatch(globalsSetModal(<ModalError description={errorText} />));
        }
    };

    const checkErrors = () => {
        let isError = false;

        if (name !== '' && name.length < 3) {
            setNameError('Минимальная длина 3 символа');
            isError = true;
        }

        if (name !== '' && name.length > 48) {
            setNameError('Максимальная длина 48 символов');
            isError = true;
        }

        if (shortDesc !== '' && shortDesc.length > 2000) {
            setShortDescError('Максимальная длина 2000 символов');
            isError = true;
        }

        if (desc !== '' && desc.length < 3) {
            setDescError('Минимальная длина 3 символа');
            isError = true;
        }

        if (desc !== '' && desc.length > 2000) {
            setDescError('Максимальная длина 2000 символов');
            isError = true;
        }

        return isError;
    };

    const changeName = value => {
        setName(value);
        setNameError('');
    };

    const changeDesc = value => {
        setDesc(value);
        setDescError('');
    };

    const changeShortDesc = value => {
        setShortDesc(value);
        setShortDescError('');
    };

    const selectType = value => {
        setType(value);
    };

    const selectVisibility = value => {
        setVisibility(value);
    };

    const changeTab = num => {
        setCurrentTab(num);
    };

    const renderInformationTab = () => (
        <>
            <MiniHead title="Название" />
            <InputText
                placeholder="Введите название"
                maxLength={48}
                setCurrentValue={appSettings.title}
                changeFunc={changeName}
                error={nameError}
            />
            <MiniHead title="Описание" />
            <TextareaText
                placeholder="Введите описание"
                maxLength={2000}
                rows={3}
                setCurrentValue={appSettings.description}
                changeFunc={changeDesc}
                error={descError}
            />
            <MiniHead title="Краткое описание" />
            <TextareaText
                placeholder="Введите краткое описание"
                maxLength={2000}
                rows={3}
                setCurrentValue={appSettings.short_desc}
                changeFunc={changeShortDesc}
                error={shortDescError}
            />
            <MiniHead title="Категория" />
            <Dropdown
                placeholder="Не выбрано"
                options={VK_APP_TYPES}
                maxOptionsShow={3}
                setCurrentValue={appSettings.app_type}
                changeFunc={selectType}
            />
        </>
    );

    const renderImagesTab = () => (
        <>
            <div className="upload-image">
                <div className="upload-image__image">
                    <div className="upload-image__inner_image" />
                </div>
                <div className="upload-image__right-part">
                    <div className="upload-image__title">Квадратная обложка</div>
                    <div className="upload-image__text">
                        Используется везде, кроме каталога приложений. Размеры загружаемого изображения –
                        <b>150×150px</b>.
                    </div>
                    <div className="upload-image__button">
                        <Button type="secondary">Выберите изображение</Button>
                    </div>
                </div>
            </div>
            <div className="upload-image">
                <div className="upload-image__image upload-image__image_large" />
                <div className="upload-image__right-part">
                    <div className="upload-image__title">Квадратная обложка для каталога</div>
                    <div className="upload-image__text">
                        Используется в каталоге приложений. Изменять обложку можно не чаще <b>2 раз в 2 недели</b>.
                        Размеры загружаемого изображения – <b>278×278px</b>.
                    </div>
                    <div className="upload-image__button">
                        <Button type="secondary">Выберите изображение</Button>
                    </div>
                </div>
            </div>
            <div className="title">Скриншоты приложения</div>
            <div className="desc">
                Используются на экране установки приложения.
                <br />
                Высота не менее <b>600px</b>, отношение сторон не более <b>2 к 1</b>.
            </div>
            <UploadScreenshots
                screens={[
                    'https://sun1-88.userapi.com/c831308/v831308102/1c54fe/dFc76sNoqQE.jpg',
                    'https://sun1-88.userapi.com/c831308/v831308102/1c54fe/dFc76sNoqQE.jpg',
                    'https://sun1-88.userapi.com/c831308/v831308102/1c54fe/dFc76sNoqQE.jpg',
                    'https://sun1-88.userapi.com/c831308/v831308102/1c54fe/dFc76sNoqQE.jpg',
                    'https://sun1-88.userapi.com/c831308/v831308102/1c54fe/dFc76sNoqQE.jpg',
                    'https://sun1-88.userapi.com/c831308/v831308102/1c54fe/dFc76sNoqQE.jpg',
                    'https://sun1-88.userapi.com/c831308/v831308102/1c54fe/dFc76sNoqQE.jpg',
                    'https://sun1-88.userapi.com/c831308/v831308102/1c54fe/dFc76sNoqQE.jpg',
                ]}
            />
        </>
    );

    const renderAdvancedTab = () => (
        <>
            <MiniHead title="Состояние" />
            <Dropdown
                placeholder="Не выбрано"
                defaultValue=""
                options={{ off: 'Приложение отключено', on: 'Приложение включено и видно всем' }}
                changeFunc={selectVisibility}
                setCurrentValue={visibility}
            />
        </>
    );

    return (
        <Modal
            id="settings"
            title="Настройки приложения"
            width={564}
            height={640}
            bodyBottom={8}
            rightButtons={[
                <Button key="panel-add-btn-cancel" type="secondary" clickFunc={closeModal}>
                    Закрыть
                </Button>,
                <Button key="panel-add-btn-add" type="primary" clickFunc={saveApp} disabled={!isSettingsLoaded}>
                    Сохранить изменения
                </Button>,
            ]}
            closeFunc={resetAppSettings}
        >
            <Tab
                titles={['Информация', 'Обложки и скриншоты', 'Дополнительно']}
                contents={[renderInformationTab(), renderImagesTab(), renderAdvancedTab()]}
                setSelectedTab={currentTab}
                setTabFunc={changeTab}
            />
        </Modal>
    );
};

export default ModalSettings;
