import { COMPONENTS } from './components';
import { ICON_BUTTON, ICON_TEXT, ICON_IMAGE, ICON_HEADER, ICON_SEPARATOR } from '../icons';

export const COMPONENTS_LIST = [
    {
        title: 'Панель заголовка',
        icon: ICON_HEADER,
        components: [COMPONENTS.header],
    },
    {
        title: 'Текст',
        icon: ICON_TEXT,
        components: [COMPONENTS.label],
    },
    {
        title: 'Кнопка',
        icon: ICON_BUTTON,
        components: [COMPONENTS.button, COMPONENTS.button_secondary, COMPONENTS.button_transparent],
    },
    {
        title: 'Картинка',
        icon: ICON_IMAGE,
        components: [COMPONENTS.image],
    },
    {
        title: 'Разделитель',
        icon: ICON_SEPARATOR,
        components: [COMPONENTS.separator],
    },
];
