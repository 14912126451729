import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorSelectComponent } from '../store/editor/actions';
import Head from './Head';
import MiniHead from './MiniHead';
import ComponentSettingsRenderer from '../classes/ComponentSettingsRenderer';
import iconBack from '../../img/icons/back.svg';
import '../../styles/component-settings-list.scss';

const ComponentSettingsList = () => {
    const dispatch = useDispatch();
    const components = useSelector(state => state.editor.panels[state.editor.currentPanel].components);
    const selectedComponentNum = useSelector(state => state.editor.selectedComponentNum);

    const goBack = () => dispatch(editorSelectComponent(-1));

    return (
        <div className="component-settings-list" key={selectedComponentNum}>
            <Head title="Свойства компонента" icon={iconBack} clickFunc={goBack} />
            <MiniHead title="Выбранный компонент" />
            <div className="component-settings-list__name">{components[selectedComponentNum].title}</div>
            {ComponentSettingsRenderer().render(components[selectedComponentNum].name)}
        </div>
    );
};

export default ComponentSettingsList;
