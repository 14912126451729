import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/button.scss';

const Button = ({ children, type, clickFunc, style, disabled, icon, positionType = 'full' }) => {
    const clickButton = () => {
        if (!disabled) {
            clickFunc();
        }
    };

    return (
        <div
            className={`button${type !== undefined ? ` button_${type}` : ' button_primary'}${
                disabled ? ` button_disabled` : ''
            }${children === undefined ? ` button_icon-only` : ''} button_${positionType}`}
            onClick={clickButton}
            style={style}
        >
            {icon !== undefined && (
                <div className="button__icon" style={{ maskImage: `url(${icon})`, WebkitMaskImage: `url(${icon})` }} />
            )}
            {children}
        </div>
    );
};

Button.propTypes = {
    children: PropTypes.node,
    type: PropTypes.string,
    clickFunc: PropTypes.func,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    positionType: PropTypes.string,
};

export default Button;
