import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/header-menu-item.scss';

const HeaderMenuItem = ({ title, icon, isMore, clickFunc }) => {
    return (
        <div className="header-menu-item" onClick={clickFunc}>
            <div
                className="header-menu-item__icon"
                style={{ maskImage: `url(${icon})`, WebkitMaskImage: `url(${icon})` }}
            />
            {title}
            {isMore && <div className="header-menu-item__icon-more" />}
        </div>
    );
};

HeaderMenuItem.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    isMore: PropTypes.bool,
    clickFunc: PropTypes.func,
};

export default HeaderMenuItem;
