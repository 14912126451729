import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InputNumber from 'rc-input-number';
import 'rc-input-number/assets/index.css';
import '../../styles/input.scss';

const InputAmount = ({
    placeholder,
    defaultValue,
    changeFunc,
    isDecimal,
    maxDecimals,
    minValue,
    maxValue,
    isClean,
    isDisabled,
}) => {
    const [value, setValue] = useState(
        Math.min(
            Math.max(defaultValue, minValue !== undefined ? minValue : -10e100),
            maxValue !== undefined ? maxValue : 10e100,
        ),
    );

    const changeValue = value => {
        setValue(value);

        if (changeFunc !== undefined) {
            if (value === '' || value === '-') {
                changeFunc(0);
            } else {
                changeFunc(value);
            }
        }
    };

    const parseValue = val => {
        if (isDecimal) {
            if (
                (val.match(new RegExp(`^(?!-0(\\.0+)?$)-?(0|[1-9]\\d*)((\\.\\d{1,${maxDecimals}})|(\\.))?$`)) ===
                    null ||
                    parseFloat(val) < minValue ||
                    parseFloat(val) > maxValue) &&
                val !== '-'
            ) {
                if (val === '') {
                    return '';
                } else {
                    return value;
                }
            } else {
                return val;
            }
        } else {
            if (
                val.match(/^(?!-0(\.0+)?$)-?(0|[1-9]\d*)?$/) === null ||
                parseFloat(val) < minValue ||
                parseFloat(val) > maxValue
            ) {
                if (val === '') {
                    return '';
                } else {
                    if (value === null || value === '') {
                        return '';
                    } else if (value === '-') {
                        return '-';
                    } else {
                        return Math.floor(value);
                    }
                }
            } else {
                if (val === '' || val === '-') {
                    return val;
                } else {
                    return Math.floor(val);
                }
            }
        }
    };

    const upArrow = <div className="rc-input-number-up-arrow" />;

    const downArrow = <div className="rc-input-number-down-arrow" />;

    return (
        <div className="input-amount">
            <InputNumber
                className={`custom${isDisabled ? ' is-disabled' : ''}`}
                value={isClean ? '' : value}
                onChange={changeValue}
                placeholder={isClean ? '' : placeholder}
                decimalSeparator={isDecimal ? '.' : undefined}
                parser={parseValue}
                upHandler={upArrow}
                downHandler={downArrow}
                max={maxValue}
                readOnly={isDisabled}
            />
        </div>
    );
};

InputAmount.propTypes = {
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    changeFunc: PropTypes.func,
    isDecimal: PropTypes.bool,
    maxDecimals: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    isClean: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

export default InputAmount;
