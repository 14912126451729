import React from 'react';

export const AUTO_SAVE_TIME = 1000;
export const DRAG_ZONE_HEIGHT = 50;
export const IOS_PHONE_SCREEN_SIZES = [
    { title: 'iPhone 5/SE', name: 'iphone_5_se', width: 320, height: 578 },
    { title: 'iPhone 6/7/8', name: 'iphone_6_7_8', width: 375, height: 667 },
    { title: 'iPhone 6/7/8 Plus', name: 'iphone_6_7_8_plus', width: 414, height: 736 },
    { title: 'iPhone X', name: 'iphone_x', width: 375, height: 812 },
];
export const SIZE_TYPES = ['px', 'pt', '%', 'auto']; // auto всегда поледний элемент
export const VK_API_VERSION = '5.124';
export const VK_APP_ID = 7585719;
export const VK_APP_TYPES = {
    lifestyle: 'Образ жизни',
    food: 'Еда и напитки',
    entertainment: 'Развлечения',
    finance: 'Финансы',
    shopping: 'Покупки',
    business: 'Бизнес',
    communication: 'Общение',
    travel: 'Путешествия',
    taxi: 'Такси',
    news: 'Новости',
    health: 'Красота и здоворье',
    education: 'Образование',
    events: 'Мероприятия',
    tools: 'Инструменты',
    other: 'Другое',
    payments: 'Оплата услуг',
};
export const DEFAULT_APP_TITLE = 'Новое приложение';
export const DEFAULT_APP_DESCRIPTION = 'Интересное описание';
export const DEFAULT_APP_TYPE = 'lifestyle';
export const ERROR_UNKNOWN = ['Неизвестная ошибка.', <br />, 'Пожалуйста, попробуйте еще раз.'];
