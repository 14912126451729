import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router } from 'react-router';
import { routerMiddleware } from 'react-router-redux';
import { rootReducer } from './js/store/reducers';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { makePropertyWritable } from './js/services/functions';
import thunk from 'redux-thunk';
import App from './App';
import logger from './logger';
import jsonSaver from './jsonSaver';

const history = createBrowserHistory();

export const store = createStore(rootReducer, applyMiddleware(thunk, routerMiddleware(history), logger(), jsonSaver()));

makePropertyWritable(window, 'navigator', 'userAgent');

const urls = ['', 'edit-app-\\d+', 'loader', 'login', 'authorization'];

const pathRegex = `/:pageId(${urls.join('|')}|)?`;

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <Route path={pathRegex} component={props => <App pageId={props.match.params.pageId} />} />
        </Router>
    </Provider>,
    document.getElementById('root'),
);
