import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { convertRgbaToHexAndAlpha, convertRgbToHex, isOverlapBottom } from '../services/functions';
import '../../styles/input.scss';

const InputColor = ({ defaultValue, changeFunc }) => {
    const [value, setValue] = useState(convertRgbToHex(defaultValue));
    const [visibleValue, setVisibleValue] = useState(convertRgbaToHexAndAlpha(defaultValue));
    const [isShowPicker, setIsShowPicker] = useState(false);
    const [isTop, setIsTop] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = event => {
            if (isShowPicker) {
                if (wrapperRef && !wrapperRef.current.contains(event.target)) {
                    setIsShowPicker(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isShowPicker]);

    const rgbaToStringOrHex = (rgb, hex) => {
        if (rgb.a === 1) {
            return hex;
        } else {
            return 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + rgb.a + ')';
        }
    };

    const changeValue = color => {
        setValue(rgbaToStringOrHex(color.rgb, color.hex));
        setVisibleValue(convertRgbaToHexAndAlpha(rgbaToStringOrHex(color.rgb, color.hex)));
    };

    const completeChange = color => {
        setValue(rgbaToStringOrHex(color.rgb, color.hex));
        setVisibleValue(convertRgbaToHexAndAlpha(rgbaToStringOrHex(color.rgb, color.hex)));

        if (changeFunc !== undefined) {
            changeFunc(rgbaToStringOrHex(color.rgb, color.hex));
        }
    };

    const openPicker = () => {
        setIsTop(isOverlapBottom(wrapperRef.current, 280));
        setIsShowPicker(!isShowPicker);
    };

    return (
        <div className={`input-color${isTop ? ' input-color_is-top' : ''}`} ref={wrapperRef}>
            <div className="input-color__input input" onClick={openPicker}>
                {visibleValue}
            </div>
            <div className="input-color__color" style={{ backgroundColor: value }} />
            <div className="input-color__picker">
                {isShowPicker && (
                    <ChromePicker
                        color={value}
                        onChange={changeValue}
                        onChangeComplete={completeChange}
                        disableAlpha={false}
                    />
                )}
            </div>
        </div>
    );
};

InputColor.propTypes = {
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    changeFunc: PropTypes.func,
};

export default InputColor;
