import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/head.scss';

const Head = ({ title, icon, iconWidth, iconHeight, clickFunc, isPaddingTop }) => {
    const clickHead = () => {
        if (clickFunc !== undefined) {
            clickFunc();
        }
    };

    return (
        <div
            className={`head${clickFunc !== undefined ? ' head_clickable' : ''}${
                isPaddingTop ? ' head_is-padding-top' : ''
            }`}
            onClick={clickHead}
        >
            {icon !== undefined && (
                <div
                    className={`head__icon`}
                    style={{
                        maskImage: `url(${icon})`,
                        WebkitMaskImage: `url(${icon})`,
                        width: iconWidth,
                        minWidth: iconWidth,
                        height: iconHeight,
                    }}
                />
            )}
            <div className="head__title">{title}</div>
        </div>
    );
};

Head.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    iconWidth: PropTypes.number,
    iconHeight: PropTypes.number,
    clickFunc: PropTypes.func,
    isPaddingTop: PropTypes.bool,
};

export default Head;
