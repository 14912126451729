import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/collapse.scss';

const Collapse = ({ title, icon, isOpened, children }) => {
    const [isOpen, setIsOpen] = useState(isOpened);

    const openOrCloseCollapse = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`collapse${isOpen ? ' collapse_opened' : ''}`}>
            <div className="collapse__title" onClick={openOrCloseCollapse}>
                <div className="collapse__left-side">
                    {icon !== undefined && (
                        <div
                            className="collapse__icon"
                            style={{ maskImage: `url(${icon})`, WebkitMaskImage: `url(${icon})` }}
                        />
                    )}
                    <div className="collapse__text">
                        <div>{title}</div>
                    </div>
                </div>
                <div className="collapse__chevron" />
            </div>
            <div className="collapse__content">{children}</div>
        </div>
    );
};

Collapse.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    isOpened: PropTypes.bool,
    children: PropTypes.node,
};

export default Collapse;
