import componentImageDarkButton from '../../../img/components/dark/button.jpg';
import componentImageLightButton from '../../../img/components/light/button.jpg';
import componentImageDarkButtonSecondary from '../../../img/components/dark/button-secondary.jpg';
import componentImageLightButtonSecondary from '../../../img/components/light/button-secondary.jpg';
import componentImageDarkButtonTransparent from '../../../img/components/dark/button-transparent.jpg';
import componentImageLightButtonTransparent from '../../../img/components/light/button-transparent.jpg';
import componentImageDarkHeader from '../../../img/components/dark/header.jpg';
import componentImageLightHeader from '../../../img/components/light/header.jpg';
import componentImageDarkLabel from '../../../img/components/dark/label.jpg';
import componentImageLightLabel from '../../../img/components/light/label.jpg';
import componentImageDarkImage from '../../../img/components/dark/image.jpg';
import componentImageLightImage from '../../../img/components/light/image.jpg';

export const COMPONENT_IMAGE_DARK_BUTTON = componentImageDarkButton;
export const COMPONENT_IMAGE_LIGHT_BUTTON = componentImageLightButton;
export const COMPONENT_IMAGE_DARK_BUTTON_SECONDARY = componentImageDarkButtonSecondary;
export const COMPONENT_IMAGE_LIGHT_BUTTON_SECONDARY = componentImageLightButtonSecondary;
export const COMPONENT_IMAGE_DARK_BUTTON_TRANSPARENT = componentImageDarkButtonTransparent;
export const COMPONENT_IMAGE_LIGHT_BUTTON_TRANSPARENT = componentImageLightButtonTransparent;
export const COMPONENT_IMAGE_DARK_HEADER = componentImageDarkHeader;
export const COMPONENT_IMAGE_LIGHT_HEADER = componentImageLightHeader;
export const COMPONENT_IMAGE_DARK_LABEL = componentImageDarkLabel;
export const COMPONENT_IMAGE_LIGHT_LABEL = componentImageLightLabel;
export const COMPONENT_IMAGE_DARK_IMAGE = componentImageDarkImage;
export const COMPONENT_IMAGE_LIGHT_IMAGE = componentImageLightImage;

export const ALL_COMPONENTS_IMAGES = [
    COMPONENT_IMAGE_DARK_BUTTON,
    COMPONENT_IMAGE_LIGHT_BUTTON,
    COMPONENT_IMAGE_DARK_BUTTON_SECONDARY,
    COMPONENT_IMAGE_LIGHT_BUTTON_SECONDARY,
    COMPONENT_IMAGE_DARK_BUTTON_TRANSPARENT,
    COMPONENT_IMAGE_LIGHT_BUTTON_TRANSPARENT,
    COMPONENT_IMAGE_DARK_HEADER,
    COMPONENT_IMAGE_LIGHT_HEADER,
    COMPONENT_IMAGE_DARK_LABEL,
    COMPONENT_IMAGE_LIGHT_LABEL,
    COMPONENT_IMAGE_DARK_IMAGE,
    COMPONENT_IMAGE_LIGHT_IMAGE,
];
