import * as types from './actionTypes';

const initialState = {
    saveProjectTimeout: null,
    modal: null,
    constructorTheme: 'light',
    pageNum: -1,
    isLoadingSomething: false,
    isProjectLoading: false,
    vkAppId: null,
};

export const globals = (state = initialState, action) => {
    switch (action.type) {
        case types.GLOBALS_SET_PROJECT_TIMEOUT: {
            clearTimeout(state.saveProjectTimeout);

            return {
                ...state,
                saveProjectTimeout: action.saveProjectTimeout,
            };
        }

        case types.GLOBALS_SET_MODAL: {
            document.body.style.overflow = 'hidden';

            return {
                ...state,
                modal: action.modal,
            };
        }

        case types.GLOBALS_CLOSE_MODAL: {
            document.body.style.overflow = null;

            return {
                ...state,
                modal: null,
            };
        }

        case types.GLOBALS_SET_CONSTRUCTOR_THEME: {
            document.body.setAttribute('constructor-theme', action.constructorTheme);

            return {
                ...state,
                constructorTheme: action.constructorTheme,
            };
        }

        case types.GLOBALS_SET_PAGE_NUM:
            return {
                ...state,
                pageNum: action.pageNum,
            };

        case types.GLOBALS_SET_IS_LOADING_SOMETHING:
            return {
                ...state,
                isLoadingSomething: action.isLoadingSomething,
            };

        case types.GLOBALS_SET_IS_PROJECT_LOADING:
            return {
                ...state,
                isProjectLoading: action.isProjectLoading,
            };

        case types.GLOBALS_SET_VK_APP_ID:
            return {
                ...state,
                vkAppId: action.vkAppId,
            };

        default:
            return state;
    }
};
