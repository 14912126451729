import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { globalsCloseModal } from '../store/globals/actions';
import '../../styles/modal.scss';

const Modal = ({ children, title, width = 600, height, leftButtons, rightButtons, bodyBottom = 24, id, closeFunc }) => {
    const dispatch = useDispatch();

    const closeModal = () => {
        dispatch(globalsCloseModal());

        if (closeFunc !== undefined) {
            closeFunc();
        }
    };

    const closeModalOwner = e => {
        if (e.target.classList.contains('modal-owner')) {
            closeModal();
        }
    };

    return (
        <div className="modal-owner" onClick={closeModalOwner}>
            <div className={`modal modal_type_${id}`} style={{ width }}>
                <div className="modal__header">
                    <div className="modal__header-title">{title}</div>
                    <div className="modal__header-close" onClick={closeModal} />
                </div>
                <div className="modal__content">
                    <div className="modal__body" style={{ marginBottom: `${bodyBottom}px`, height }}>
                        {children}
                    </div>
                    {(leftButtons !== undefined || rightButtons !== undefined) && (
                        <div className="modal__bottom-buttons">
                            <div className="modal__left-buttons">{leftButtons}</div>
                            <div className="modal__right-buttons">{rightButtons}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    leftButtons: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
    rightButtons: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
    bodyBottom: PropTypes.number,
    id: PropTypes.string,
    closeFunc: PropTypes.func,
};

export default Modal;
