import iconAdd from '../../img/icons/add.svg';
import iconAppDefaultButtons from '../../img/icons/app-default-buttons.svg';
import iconBack from '../../img/icons/back.svg';
import iconBackground from '../../img/icons/background.svg';
import iconBold from '../../img/icons/bold.svg';
import iconButton from '../../img/icons/button.svg';
import iconCamera from '../../img/icons/camera.svg';
import iconCheck from '../../img/icons/check.svg';
import iconClick from '../../img/icons/click.svg';
import iconClose from '../../img/icons/close.svg';
import iconCloseSquareHover from '../../img/icons/close-square-hover.svg';
import iconCloseSquareNormal from '../../img/icons/close-square-normal.svg';
import iconCopy from '../../img/icons/copy.svg';
import iconDelete from '../../img/icons/delete.svg';
import iconDropdownDown from '../../img/icons/dropdown-down.svg';
import iconDropdownMiniDown from '../../img/icons/dropdown-mini-down.svg';
import iconDropdownMiniUp from '../../img/icons/dropdown-mini-up.svg';
import iconDropdownMiniUpDown from '../../img/icons/dropdown-mini-up-down.svg';
import iconDropdownUp from '../../img/icons/dropdown-up.svg';
import iconEdit from '../../img/icons/edit.svg';
import iconError from '../../img/icons/error.svg';
import iconGoto from '../../img/icons/goto.svg';
import iconHeader from '../../img/icons/header.svg';
import iconHome from '../../img/icons/home.svg';
import iconImage from '../../img/icons/image.svg';
import iconItalics from '../../img/icons/italics.svg';
import iconLiftDown from '../../img/icons/lift-down.svg';
import iconLiftUp from '../../img/icons/lift-up.svg';
import iconLoad from '../../img/icons/load.svg';
import iconMoon from '../../img/icons/moon.svg';
import iconOther from '../../img/icons/other.svg';
import iconPadding from '../../img/icons/padding.svg';
import iconPreview from '../../img/icons/preview.svg';
import iconSeparator from '../../img/icons/separator.svg';
import iconSettings from '../../img/icons/settings.svg';
import iconStat from '../../img/icons/stat.svg';
import iconStrike from '../../img/icons/strike.svg';
import iconStyle from '../../img/icons/style.svg';
import iconSun from '../../img/icons/sun.svg';
import iconText from '../../img/icons/text.svg';
import iconUnderline from '../../img/icons/underline.svg';
import iconUnpreview from '../../img/icons/unpreview.svg';

export const ICON_ADD = iconAdd;
export const ICON_APP_DEFAULT_BUTTONS = iconAppDefaultButtons;
export const ICON_BACK = iconBack;
export const ICON_BACKGROUND = iconBackground;
export const ICON_BOLD = iconBold;
export const ICON_BUTTON = iconButton;
export const ICON_CAMERA = iconCamera;
export const ICON_CHECK = iconCheck;
export const ICON_CLICK = iconClick;
export const ICON_CLOSE = iconClose;
export const ICON_CLOSE_SQUARE_HOVER = iconCloseSquareHover;
export const ICON_CLOSE_SQUARE_NORMAL = iconCloseSquareNormal;
export const ICON_COPY = iconCopy;
export const ICON_DELETE = iconDelete;
export const ICON_DROPDOWN_DOWN = iconDropdownDown;
export const ICON_DROPDOWN_MINI_DOWN = iconDropdownMiniDown;
export const ICON_DROPDOWN_MINI_UP = iconDropdownMiniUp;
export const ICON_DROPDOWN_MINI_UP_DOWN = iconDropdownMiniUpDown;
export const ICON_DROPDOWN_UP = iconDropdownUp;
export const ICON_EDIT = iconEdit;
export const ICON_ERROR = iconError;
export const ICON_GOTO = iconGoto;
export const ICON_HEADER = iconHeader;
export const ICON_HOME = iconHome;
export const ICON_IMAGE = iconImage;
export const ICON_ITALICS = iconItalics;
export const ICON_LIFT_DOWN = iconLiftDown;
export const ICON_LIFT_UP = iconLiftUp;
export const ICON_LOAD = iconLoad;
export const ICON_MOON = iconMoon;
export const ICON_OTHER = iconOther;
export const ICON_PADDING = iconPadding;
export const ICON_PREVIEW = iconPreview;
export const ICON_SETTINGS = iconSettings;
export const ICON_SEPARATOR = iconSeparator;
export const ICON_STAT = iconStat;
export const ICON_STRIKE = iconStrike;
export const ICON_STYLE = iconStyle;
export const ICON_SUN = iconSun;
export const ICON_TEXT = iconText;
export const ICON_UNDERLINE = iconUnderline;
export const ICON_UNPREVIEW = iconUnpreview;

export const ALL_ICONS_AND_IMAGES = [
    ICON_ADD,
    ICON_APP_DEFAULT_BUTTONS,
    ICON_BACK,
    ICON_BACKGROUND,
    ICON_BOLD,
    ICON_BUTTON,
    ICON_CAMERA,
    ICON_CHECK,
    ICON_CLICK,
    ICON_CLOSE,
    ICON_CLOSE_SQUARE_HOVER,
    ICON_CLOSE_SQUARE_NORMAL,
    ICON_COPY,
    ICON_DELETE,
    ICON_DROPDOWN_DOWN,
    ICON_DROPDOWN_MINI_DOWN,
    ICON_DROPDOWN_MINI_UP,
    ICON_DROPDOWN_MINI_UP_DOWN,
    ICON_DROPDOWN_UP,
    ICON_EDIT,
    ICON_ERROR,
    ICON_GOTO,
    ICON_HEADER,
    ICON_HOME,
    ICON_IMAGE,
    ICON_ITALICS,
    ICON_LIFT_DOWN,
    ICON_LIFT_UP,
    ICON_LOAD,
    ICON_MOON,
    ICON_OTHER,
    ICON_PADDING,
    ICON_PREVIEW,
    ICON_SETTINGS,
    ICON_SEPARATOR,
    ICON_STAT,
    ICON_STRIKE,
    ICON_STYLE,
    ICON_SUN,
    ICON_UNDERLINE,
    ICON_UNPREVIEW,
];
