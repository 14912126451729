import * as types from './actionTypes';
import { COMPONENTS_HEADERS } from '../../constants/components/components-headers';
import { copyArray } from '../../services/functions';

const defaultPanels = {
    zero: {
        title: '',
        isStartup: true,
        hasHeader: false,
        components: [],
    },
};

const initialState = {
    currentPanel: 'zero',
    panels: copyArray(defaultPanels),
    selectedComponentNum: -1,
    isDragging: false,
    dragPosition: { x: 0, y: 0 },
    dragOffset: { x: 0, y: 0 },
    dragOptions: {
        title: '',
        icon: '',
        images: ['', ''],
    },
    isOnDraggingZone: false,
    isOnMiddleZone: false,
    hoverComponentNum: -1,
    hoverComponentOverTop: false,
    dragComponentType: '',
    selectedPhone: '',
    constructorViewerSize: [0, 0],
    isDarkTheme: false,
    isIOS: true,
    phoneScreen: 'iphone_x',
    projectKey: 1,
    lastScrollPosition: 0,
};

export const editor = (state = initialState, action) => {
    switch (action.type) {
        case types.EDITOR_ADD_COMPONENT: {
            const newPanels = { ...state.panels };

            let newHasHeader = newPanels[state.currentPanel].hasHeader;
            const newComponents = [];

            if (COMPONENTS_HEADERS.includes(action.component.name)) {
                if (!state.panels[state.currentPanel].hasHeader) {
                    newComponents.push({
                        componentName: action.component.componentName,
                        isVisible: true,
                        data: action.component.data,
                        title: action.component.title,
                        icon: action.component.icon,
                        name: action.component.name,
                    });
                    newHasHeader = true;
                }
                state.panels[state.currentPanel].components.forEach(component => {
                    newComponents.push(component);
                });
            } else {
                if (state.panels[state.currentPanel].components.length > 0) {
                    state.panels[state.currentPanel].components.forEach((component, i) => {
                        if (
                            i === 0 &&
                            state.hoverComponentNum === 0 &&
                            state.panels[state.currentPanel].hasHeader &&
                            state.hoverComponentOverTop
                        ) {
                            newComponents.push(component);
                            newComponents.push({
                                componentName: action.component.componentName,
                                isVisible: true,
                                data: action.component.data,
                                title: action.component.title,
                                icon: action.component.icon,
                                name: action.component.name,
                            });
                        } else {
                            if (i === state.hoverComponentNum) {
                                if (!state.hoverComponentOverTop) {
                                    newComponents.push(component);
                                }
                                newComponents.push({
                                    componentName: action.component.componentName,
                                    isVisible: true,
                                    data: action.component.data,
                                    title: action.component.title,
                                    icon: action.component.icon,
                                    name: action.component.name,
                                });
                                if (state.hoverComponentOverTop) {
                                    newComponents.push(component);
                                }
                            } else {
                                newComponents.push(component);
                            }
                        }
                    });
                } else {
                    newComponents.push({
                        componentName: action.component.componentName,
                        isVisible: true,
                        data: action.component.data,
                        title: action.component.title,
                        icon: action.component.icon,
                        name: action.component.name,
                    });
                }
            }

            newPanels[state.currentPanel].components = [...newComponents];
            newPanels[state.currentPanel].hasHeader = newHasHeader;

            return {
                ...state,
                panels: { ...newPanels },
            };
        }

        case types.EDITOR_LOAD_COMPONENT: {
            const newPanels = { ...state.panels };
            const newComponents = [...state.panels[action.panelKey].components];

            newComponents.push({
                componentName: action.component.componentName,
                isVisible: action.component.isVisible,
                data: action.component.data,
                title: action.component.title,
                icon: action.component.icon,
                name: action.component.name,
            });

            newPanels[action.panelKey].components = [...newComponents];

            return {
                ...state,
                panels: { ...newPanels },
            };
        }

        case types.EDITOR_ADD_PANEL: {
            const newPanels = { ...state.panels };

            newPanels[action.panelKey] = {
                isStartup: false,
                title: action.title,
                hasHeader: false,
                components: [],
            };

            return {
                ...state,
                panels: { ...newPanels },
            };
        }

        case types.EDITOR_LOAD_PANEL: {
            const newPanels = { ...state.panels };

            newPanels[action.panelKey] = {
                title: action.panel.title,
                isStartup: action.panel.is_startup === undefined ? false : action.panel.is_startup,
                hasHeader: action.panel.has_header === undefined ? false : action.panel.has_header,
                components: [],
            };

            return {
                ...state,
                panels: { ...newPanels },
            };
        }

        case types.EDITOR_SELECT_PANEL: {
            return {
                ...state,
                currentPanel: action.currentPanel,
                selectedComponentNum: -1,
            };
        }

        case types.EDITOR_SET_STARTUP_PANEL: {
            const newPanels = { ...state.panels };

            Object.keys(newPanels).forEach(panelKey => {
                newPanels[panelKey].isStartup = panelKey === action.panelKey;
            });

            return {
                ...state,
                panels: { ...newPanels },
            };
        }

        case types.EDITOR_SET_PANEL_TITLE: {
            const newPanels = { ...state.panels };
            newPanels[action.panelKey].title = action.title;

            return {
                ...state,
                panels: { ...newPanels },
            };
        }

        case types.EDITOR_DELETE_PANEL: {
            const newPanels = { ...state.panels };
            delete newPanels[action.panelKey];

            return {
                ...state,
                panels: { ...newPanels },
            };
        }

        case types.EDITOR_SET_IS_DRAGGING:
            return {
                ...state,
                isDragging: action.isDragging,
            };

        case types.EDITOR_SET_DRAG_POSITION:
            return {
                ...state,
                dragPosition: {
                    x: action.dragPositionX,
                    y: action.dragPositionY,
                },
            };

        case types.EDITOR_SET_DRAG_OFFSET:
            return {
                ...state,
                dragOffset: {
                    x: action.dragOffsetX,
                    y: action.dragOffsetY,
                },
            };

        case types.EDITOR_SET_IS_ON_DRAGGING_ZONE:
            return {
                ...state,
                isOnDraggingZone: action.isOnDraggingZone,
            };

        case types.EDITOR_SET_HOVER_COMPONENT_NUM:
            return {
                ...state,
                hoverComponentNum: action.hoverComponentNum,
            };

        case types.EDITOR_SET_HOVER_COMPONENT_OVER_TOP:
            return {
                ...state,
                hoverComponentOverTop: action.hoverComponentOverTop,
            };

        case types.EDITOR_SET_DRAG_COMPONENT_TYPE:
            return {
                ...state,
                dragComponentType: action.dragComponentType,
            };

        case types.EDITOR_SET_DRAG_COMPONENT_OPTIONS:
            return {
                ...state,
                dragOptions: { ...action.dragOptions },
            };

        case types.EDITOR_SET_IS_ON_MIDDLE_ZONE:
            return {
                ...state,
                isOnMiddleZone: action.isOnMiddleZone,
            };

        case types.EDITOR_RESET_DRAGGING:
            return {
                ...state,
                isDragging: false,
                isOnDraggingZone: false,
                isOnMiddleZone: false,
                draggingComponent: null,
                dragPosition: { x: 0, y: 0 },
                dragOffset: { x: 0, y: 0 },
                hoverComponentNum: -1,
                dragOptions: {
                    title: '',
                    icon: '',
                    images: ['', ''],
                },
                hoverComponentOverTop: false,
                dragComponentType: '',
            };

        case types.EDITOR_SET_CONSTRUCTOR_VIEWER_SIZE:
            return {
                ...state,
                selectedPhone: action.selectedPhone,
                constructorViewerSize: action.constructorViewerSize,
            };

        case types.EDITOR_COMPONENT_DOWN: {
            const newPanels = { ...state.panels };
            const newComponents = [...state.panels[state.currentPanel].components];
            let newSelectedComponentNum = state.selectedComponentNum;

            if (action.num !== newComponents.length - 1) {
                [newComponents[action.num], newComponents[action.num + 1]] = [
                    newComponents[action.num + 1],
                    newComponents[action.num],
                ];

                if (state.selectedComponentNum === action.num) {
                    newSelectedComponentNum = action.num + 1;
                } else if (state.selectedComponentNum === action.num + 1) {
                    newSelectedComponentNum = action.num;
                }
            }

            newPanels[state.currentPanel].components = [...newComponents];

            return {
                ...state,
                panels: { ...newPanels },
                selectedComponentNum: newSelectedComponentNum,
            };
        }

        case types.EDITOR_COMPONENT_UP: {
            const newPanels = { ...state.panels };
            const newComponents = [...state.panels[state.currentPanel].components];
            let newSelectedComponentNum = state.selectedComponentNum;

            if (action.num !== 0) {
                if (!COMPONENTS_HEADERS.includes(newComponents[action.num - 1].name)) {
                    [newComponents[action.num], newComponents[action.num - 1]] = [
                        newComponents[action.num - 1],
                        newComponents[action.num],
                    ];

                    if (state.selectedComponentNum === action.num) {
                        newSelectedComponentNum = action.num - 1;
                    } else if (state.selectedComponentNum === action.num - 1) {
                        newSelectedComponentNum = action.num;
                    }
                }
            }

            newPanels[state.currentPanel].components = [...newComponents];

            return {
                ...state,
                panels: { ...newPanels },
                selectedComponentNum: newSelectedComponentNum,
            };
        }

        case types.EDITOR_COMPONENT_COPY: {
            const newPanels = { ...state.panels };
            const newComponents = [...state.panels[state.currentPanel].components];

            const component = {
                isVisible: newComponents[action.num].isVisible,
                data: copyArray(newComponents[action.num].data),
                title: newComponents[action.num].title,
                icon: newComponents[action.num].icon,
                name: newComponents[action.num].name,
            };
            let newSelectedComponentNum = state.selectedComponentNum;

            newComponents.splice(action.num, 0, component);

            if (action.num < newSelectedComponentNum) {
                newSelectedComponentNum++;
            }

            newPanels[state.currentPanel].components = [...newComponents];

            return {
                ...state,
                panels: { ...newPanels },
                selectedComponentNum: newSelectedComponentNum,
            };
        }

        case types.EDITOR_COMPONENT_DELETE: {
            const newPanels = { ...state.panels };
            const newComponents = [...state.panels[state.currentPanel].components];
            let newSelectedNum = state.selectedComponentNum;

            let newHasHeader = state.panels[state.currentPanel].hasHeader;
            if (COMPONENTS_HEADERS.includes(newComponents[action.num].name)) {
                newHasHeader = false;
            }

            newComponents.splice(action.num, 1);

            newPanels[state.currentPanel].components = [...newComponents];
            newPanels[state.currentPanel].hasHeader = newHasHeader;

            if (state.selectedComponentNum !== -1) {
                if (action.num === state.selectedComponentNum) {
                    newSelectedNum = -1;
                } else if (action.num < state.selectedComponentNum) {
                    newSelectedNum = state.selectedComponentNum - 1;
                } else {
                    newSelectedNum = state.selectedComponentNum;
                }
            }

            return {
                ...state,
                panels: { ...newPanels },
                selectedComponentNum: newSelectedNum,
            };
        }

        case types.EDITOR_COMPONENT_PREVIEW: {
            const newPanels = { ...state.panels };
            const newComponents = [...state.panels[state.currentPanel].components];

            if (action.isVisible === undefined) {
                newComponents[action.num].isVisible = !state.panels[state.currentPanel].components[action.num]
                    .isVisible;
            } else {
                newComponents[action.num].isVisible = action.isVisible;
            }

            newPanels[state.currentPanel].components = [...newComponents];

            return {
                ...state,
                panels: { ...newPanels },
            };
        }

        case types.EDITOR_SELECT_COMPONENT:
            return {
                ...state,
                selectedComponentNum: state.selectedComponentNum === action.num ? -1 : action.num,
            };

        case types.EDITOR_SET_COMPONENT_DATA: {
            const newPanels = { ...state.panels };
            const newComponents = [...state.panels[state.currentPanel].components];

            newComponents[action.selectedComponentNum].data[action.struct] = action.data;

            newPanels[state.currentPanel].components = [...newComponents];

            return {
                ...state,
                panels: { ...newPanels },
            };
        }

        case types.EDITOR_SET_IS_DARK_THEME: {
            return {
                ...state,
                isDarkTheme: action.isDarkTheme,
            };
        }

        case types.EDITOR_SET_IS_IOS: {
            return {
                ...state,
                isIOS: action.isIOS,
            };
        }

        case types.EDITOR_SET_PHONE_SCREEN: {
            return {
                ...state,
                phoneScreen: action.phoneScreen,
            };
        }

        case types.EDITOR_SET_PROJECT_KEY: {
            return {
                ...state,
                projectKey: action.projectKey,
            };
        }

        case types.EDITOR_REMOVE_ZERO_PANEL: {
            const newPanels = { ...state.panels };
            delete newPanels.zero;

            return {
                ...state,
                panels: { ...newPanels },
            };
        }

        case types.EDITOR_RESET_PANELS_AND_PROJECT: {
            return {
                ...state,
                panels: copyArray(defaultPanels),
                currentPanel: 'zero',
                selectedPhone: '',
                constructorViewerSize: [0, 0],
                isDarkTheme: false,
                isIOS: true,
                phoneScreen: 'iphone_x',
            };
        }

        default:
            return state;
    }
};
