import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editorAddPanel, editorSelectPanel } from '../store/editor/actions';
import { globalsCloseModal } from '../store/globals/actions';
import Modal from './Modal';
import InputText from './InputText';
import MiniHead from './MiniHead';
import Button from './Button';

const ModalAddPanel = () => {
    const dispatch = useDispatch();
    const panels = useSelector(state => state.editor.panels);
    const [name, setName] = useState(`Новая страница ${Object.keys(panels).length + 1}`);

    const addPanel = () => {
        const num = Math.floor(Math.random() * 1000000000000000);

        if (name === '') {
            dispatch(editorAddPanel(`panel_${num}`, `Новая страница ${Object.keys(panels).length + 1}`));
        } else {
            dispatch(editorAddPanel(`panel_${num}`, name));
        }

        dispatch(editorSelectPanel(`panel_${num}`));
        closeModal();
    };

    const changeName = value => {
        setName(value);
    };

    const closeModal = () => {
        dispatch(globalsCloseModal());
    };

    return (
        <Modal
            id="add-panel"
            title="Добавление"
            width={500}
            bodyBottom={8}
            rightButtons={[
                <Button key="panel-add-btn-cancel" type="secondary" clickFunc={closeModal}>
                    Отмена
                </Button>,
                <Button key="panel-add-btn-add" type="primary" clickFunc={addPanel}>
                    Добавить страницу
                </Button>,
            ]}
        >
            <MiniHead title="Название" />
            <InputText placeholder="Введите название страницы" defaultValue={name} changeFunc={changeName} />
        </Modal>
    );
};

export default ModalAddPanel;
