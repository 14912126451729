import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/mini-head.scss';

const MiniHead = ({ title }) => {
    return <div className="mini-head">{title}</div>;
};

MiniHead.propTypes = {
    title: PropTypes.string,
};

export default MiniHead;
