import axios from 'axios';
import { store } from '../../index';
import { push } from 'react-router-redux';

const dispatch = action => store.dispatch(action);

export const checkServerAuth = isNeedSettings => {
    return axiosRequest('check-auth', { is_need_settings: isNeedSettings });
};

export const serverAuth = code => {
    return axiosRequest('auth', { code });
};

export const logout = () => {
    return axiosRequest('sign-out');
};

export const setIsDarkTheme = isDarkTheme => {
    return axiosRequest('set-is-dark-theme', { is_dark_theme: isDarkTheme });
};

export const getApps = () => {
    return axiosRequest('get-apps', {});
};

export const createApp = (title, description, type) => {
    const defaultAppJSON = JSON.stringify(require('../../helpers/default-project.json'));

    return axiosRequest('create-app', { title, description, type, default_app_json: defaultAppJSON });
};

export const deleteApp = appId => {
    return axiosRequest('delete-app', { id: appId });
};

export const copyApp = (appId, title) => {
    return axiosRequest('copy-app', { id: appId, title });
};

export const loadApp = appId => {
    return axiosRequest('load-app', { id: appId });
};

export const saveApp = (appId, appJSON) => {
    return axiosRequest('save-app', { id: appId, app_json: appJSON });
};

export const getAppSettings = appId => {
    return axiosRequest('get-app-settings', { id: appId });
};

export const updateApp = (id, title, description, shortDesc, type, visibility) => {
    return axiosRequest('save-app-settings', { id, title, description, type, short_desc: shortDesc, visibility });
};

export const updateAppDefaultLink = id => {
    return axiosRequest('update-app-default-link', { id });
};

export const axiosRequest = async (url, postData = {}) => {
    const successFunc = response => {
        if (response.data.result === 'success') {
            return response.data;
        } else {
            if (response.data.result === 'auth_fail') {
                dispatch(push('/login'));
                return { result: 'auth_fail' };
            }

            return { result: 'fail', error: response.data.error !== undefined ? response.data.error : 'unknown' };
        }
    };

    const failFunc = () => {
        return { result: 'fail', error: 'unknown' };
    };

    // Подготавливаем параметры
    const preparedPostData = new FormData();
    Object.keys(postData).forEach(key => {
        preparedPostData.append(key, postData[key]);
    });

    // Делаем запрос
    return axios({
        method: 'post',
        url: `${process.env.REACT_APP_BACK_URL}/${url}/`,
        data: preparedPostData,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
    })
        .then(result => successFunc(result))
        .catch(error => failFunc(error));
};
