import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/input.scss';

const InputText = ({ placeholder, defaultValue, changeFunc, maxLength, error, setCurrentValue }) => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        if (setCurrentValue !== undefined) {
            setValue(setCurrentValue);
        }
    }, [setCurrentValue]);

    const changeValue = e => {
        setValue(e.target.value);

        if (changeFunc !== undefined) {
            changeFunc(e.target.value);
        }
    };

    return (
        <>
            <input
                className={`input${error && error !== '' ? ' input_error' : ''}`}
                placeholder={placeholder}
                value={value}
                onChange={changeValue}
                maxLength={maxLength}
            />
            {error && error !== '' && <div className="input-error">{error}</div>}
        </>
    );
};

InputText.propTypes = {
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    changeFunc: PropTypes.func,
    maxLength: PropTypes.number,
    error: PropTypes.string,
    setCurrentValue: PropTypes.string,
};

export default InputText;
