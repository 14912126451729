import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SIZE_TYPES } from '../services/locals';
import InputAmount from './InputAmount';
import '../../styles/input.scss';

const InputSize = ({ placeholder, defaultValue, changeFunc, isDecimal, maxDecimals, minValue, maxValue }) => {
    const [value, setValue] = useState('');
    const [sizeTypeNum, setSizeTypeNum] = useState(0);

    useEffect(() => {
        const sizeData = getSizeData(defaultValue);
        setValue(sizeData.value);
        setSizeTypeNum(sizeData.typeNum);
    }, []);

    const getSizeData = sizeString => {
        if (sizeString !== undefined) {
            if (sizeString === SIZE_TYPES.length - 1) {
                return { value: '', typeNum: SIZE_TYPES.length - 1 };
            } else {
                let result = { value: '', typeNum: 0 };
                SIZE_TYPES.forEach((st, i) => {
                    if (sizeString.indexOf(st) !== -1) {
                        result = { value: sizeString.substr(0, sizeString.indexOf(st)), typeNum: i };
                    }
                });
                return result;
            }
        }

        return { value: '', typeNum: 0 };
    };

    const changeSizeType = () => {
        let newSizeTypeNum = sizeTypeNum;
        if (sizeTypeNum === SIZE_TYPES.length - 1) {
            newSizeTypeNum = 0;
        } else {
            newSizeTypeNum++;
        }

        if (newSizeTypeNum === SIZE_TYPES.length - 1) {
            changeFunc('auto');
        } else {
            changeFunc(value + SIZE_TYPES[newSizeTypeNum]);
        }

        setSizeTypeNum(newSizeTypeNum);
    };

    const changeWithPrefix = value => {
        setValue(value);

        if (changeFunc !== undefined) {
            if (isAuto()) {
                changeFunc('auto');
            } else {
                if (value === '') {
                    changeFunc(`0${SIZE_TYPES[sizeTypeNum]}`);
                } else {
                    changeFunc(value + SIZE_TYPES[sizeTypeNum]);
                }
            }
        }
    };

    const isAuto = () => sizeTypeNum === SIZE_TYPES.length - 1;

    const amountProps = {
        placeholder,
        defaultValue: getSizeData(defaultValue).value,
        changeFunc: changeWithPrefix,
        isDecimal,
        maxDecimals,
        minValue,
        maxValue,
        isClean: isAuto(),
        isDisabled: isAuto(),
    };

    return (
        <div className={`input-size${isAuto() ? ' input-size_auto' : ''}`}>
            <div className="input-size__select" onClick={changeSizeType}>
                {SIZE_TYPES[sizeTypeNum]}
            </div>
            <div>
                <InputAmount {...amountProps} />
            </div>
        </div>
    );
};

InputSize.propTypes = {
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    changeFunc: PropTypes.func,
    isDecimal: PropTypes.bool,
    maxDecimals: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
};

export default InputSize;
