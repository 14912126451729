import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { globalsSetModal } from '../store/globals/actions';
import { editorSelectPanel } from '../store/editor/actions';
import CustomScroll from 'react-custom-scroll';
import ModalAddPanel from './ModalAddPanel';
import ModalEditPanel from './ModalEditPanel';
import '../../styles/page-selector.scss';
import '../../styles/custom-scroll.scss';
import PopupMenu from './PopupMenu';

const PageSelector = () => {
    const dispatch = useDispatch();
    const currentPanel = useSelector(state => state.editor.currentPanel);
    const panels = useSelector(state => state.editor.panels);
    const [isOpened, setIsOpened] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = event => {
            if (isOpened) {
                if (wrapperRef && !wrapperRef.current.contains(event.target)) {
                    setIsOpened(false);
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpened]);

    const openList = () => {
        setIsOpened(!isOpened);
    };

    const selectOption = (e, panelKey) => {
        setIsOpened(false);

        if (e.target.classList.contains('page-selector--container-option--text')) {
            dispatch(editorSelectPanel(panelKey));
        } else {
            clickEdit(panelKey);
        }
    };

    const clickEdit = panelKey => {
        dispatch(globalsSetModal(<ModalEditPanel panelKey={panelKey} />));
    };

    const calcMaxHeight = () => {
        const maxElements = 5;
        if (Object.keys(panels).length + 1 > maxElements) {
            return { height: `${maxElements * 45 + 45}px` };
        } else {
            return { height: 'auto' };
        }
    };

    const addNewPage = (e, setIsOpened) => {
        setIsOpened(false);
        dispatch(globalsSetModal(<ModalAddPanel />));
    };

    const renderOptions = () =>
        Object.keys(panels).map(pKey => (
            <div className="page-selector__option" key={pKey}>
                <div className="page-selector__option-text">{panels[pKey].title}</div>
                <div className="page-selector__option-icon">
                    <div className="page-selector__option-icon-inner" />
                </div>
            </div>
        ));

    const optionsClickFunc = panelKey => {
        return (e, setIsOpened) => {
            if (
                e.target.classList.contains('page-selector__option-icon') ||
                e.target.classList.contains('page-selector__option-icon-inner')
            ) {
                clickEdit(panelKey);
            } else {
                dispatch(editorSelectPanel(panelKey));
            }

            setIsOpened(false);
        };
    };

    const renderOptionsClickFunc = () => Object.keys(panels).map(pKey => optionsClickFunc(pKey));

    const renderAddOption = () => (
        <div className="page-selector__option" key="add-new-page">
            <div className="page-selector__option-add-page">
                <div className="page-selector__add-page-icon" />
                <div className="page-selector__add-page-title">Добавить страницу</div>
            </div>
        </div>
    );
    //
    // return (
    //     <div className={`page-selector${isOpened ? ' page-selector_opened' : ''}`} ref={wrapperRef}>
    //         {renderSelected()}
    //         <div className="page-selector__options" style={calcMaxHeight()}>
    //             <CustomScroll heightRelativeToParent="100%">
    //                 {renderOptions()}
    //                 <div className="page-selector__option" onClick={addNewPage} key="add-new-page">
    //                     <div className="page-selector__option-add-page">
    //                         <div className="page-selector__add-page-icon" />
    //                         <div className="page-selector__add-page-title">Добавить страницу</div>
    //                     </div>
    //                 </div>
    //             </CustomScroll>
    //         </div>
    //     </div>
    // );

    return (
        <PopupMenu
            title={panels[currentPanel].title}
            items={[...renderOptions(), renderAddOption()]}
            itemsClickFunc={[...renderOptionsClickFunc(), addNewPage]}
            defaultWidth={350}
        />
    );
};

export default PageSelector;
