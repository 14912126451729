import * as types from './actionTypes';

const initialState = {
    apps: [],
    appSettings: {},
};

export const apps = (state = initialState, action) => {
    switch (action.type) {
        case types.APPS_SET_APPS:
            return {
                ...state,
                apps: action.apps,
            };

        case types.APPS_NEW_APP: {
            const newApps = [action.newApp, ...state.apps];

            return {
                ...state,
                apps: newApps,
            };
        }

        case types.APPS_DELETE_APP: {
            const newApps = [...state.apps.filter(app => +app.id !== action.appId)];

            return {
                ...state,
                apps: newApps,
            };
        }

        case types.APPS_SET_APP_SETTINGS: {
            return {
                ...state,
                appSettings: action.appSettings,
            };
        }

        default:
            return state;
    }
};
