import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    DEFAULT_APP_DESCRIPTION,
    DEFAULT_APP_TITLE,
    DEFAULT_APP_TYPE,
    ERROR_UNKNOWN,
    VK_APP_TYPES,
} from '../services/locals';
import { globalsCloseModal, globalsSetModal } from '../store/globals/actions';
import { createApp } from '../services/axios';
import { appsNewApp } from '../store/apps/actions';
import Modal from './Modal';
import InputText from './InputText';
import MiniHead from './MiniHead';
import Button from './Button';
import Dropdown from './Dropdown';
import TextareaText from './TextareaText';
import ModalError from './ModalError';
import { renderErrors } from '../services/functions';

const ModalCreateApp = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [type, setType] = useState(DEFAULT_APP_TYPE);
    const [nameError, setNameError] = useState('');
    const [descError, setDescError] = useState('');
    const defaultTitle = DEFAULT_APP_TITLE;
    const defaultDesc = DEFAULT_APP_DESCRIPTION;

    const createNewApp = async () => {
        if (checkErrors()) {
            return;
        }

        closeModal();

        const createResult = await createApp(name === '' ? defaultTitle : name, desc === '' ? defaultDesc : desc, type);
        if (createResult.result === 'success') {
            dispatch(appsNewApp({ id: createResult.app.id, title: createResult.app.title, members: 0 }));
        } else if (createResult.result === 'fail') {
            const errorText = renderErrors(createResult.error, {
                cant_save_default_project: [
                    'Ошибка сохранения проекта на сервер.',
                    'Пожалуйста, повторите попытку позже.',
                ],
                cant_create_app: ['Не удалось создать приложение.', 'Пожалуйста, повторите попытку позже.'],
            });
            dispatch(globalsSetModal(<ModalError description={errorText} />));
        }
    };

    const checkErrors = () => {
        let isError = false;

        if (name !== '' && name.length < 3) {
            setNameError('Минимальная длина 3 символа');
            isError = true;
        }

        if (name !== '' && name.length > 48) {
            setNameError('Максимальная длина 48 символов');
            isError = true;
        }

        if (desc !== '' && desc.length < 3) {
            setDescError('Минимальная длина 3 символа');
            isError = true;
        }

        if (desc !== '' && desc.length > 2000) {
            setDescError('Максимальная длина 2000 символов');
            isError = true;
        }

        return isError;
    };

    const changeName = value => {
        setName(value);
        setNameError('');
    };

    const changeDesc = value => {
        setDesc(value);
        setDescError('');
    };

    const selectType = value => {
        setType(value);
    };

    const closeModal = () => {
        dispatch(globalsCloseModal());
    };

    return (
        <Modal
            id="create-app"
            title="Создание нового приложения"
            width={500}
            height={450}
            bodyBottom={8}
            rightButtons={[
                <Button key="panel-add-btn-cancel" type="secondary" clickFunc={closeModal}>
                    Отмена
                </Button>,
                <Button key="panel-add-btn-add" type="primary" clickFunc={createNewApp}>
                    Создать
                </Button>,
            ]}
        >
            <MiniHead title="Название" />
            <InputText
                placeholder={defaultTitle}
                defaultValue={name}
                changeFunc={changeName}
                maxLength={48}
                error={nameError}
            />
            <MiniHead title="Описание" />
            <TextareaText
                placeholder={defaultDesc}
                defaultValue={desc}
                changeFunc={changeDesc}
                maxLength={20000}
                rows={3}
                error={descError}
            />
            <MiniHead title="Категория" />
            <Dropdown options={VK_APP_TYPES} maxOptionsShow={3} defaultValue={type} changeFunc={selectType} />
        </Modal>
    );
};

export default ModalCreateApp;
