import { push } from 'react-router-redux';
import { store } from '../../index';
import { VK_API_VERSION, VK_APP_ID } from './locals';
import { authSetFirstAuthChecked, authSetSettingsLoaded } from '../store/auth/actions';
import { checkServerAuth, logout, serverAuth } from './axios';
import { globalsSetConstructorTheme } from '../store/globals/actions';

const dispatch = action => store.dispatch(action);
const SCOPES = ['offline'];

export const checkAuth = async () => {
    const firstAuthChecked = store.getState().auth.firstAuthChecked;
    const settingsLoaded = store.getState().auth.settingsLoaded;

    //  Проверяем авторизацию через сервер
    const serverAuthResult = await checkServerAuth(!settingsLoaded);
    const isAuth = serverAuthResult.result === 'success';

    // Если это первая проверка
    if (!firstAuthChecked) {
        dispatch(authSetFirstAuthChecked(true));
    }

    // Устанавливаем настройки
    if (!settingsLoaded && isAuth) {
        dispatch(authSetSettingsLoaded(true));
        dispatch(globalsSetConstructorTheme(!!+serverAuthResult.user.is_dark_theme ? 'dark' : 'light'));
    }

    // Перенаправляем в зависимости от результата
    redirectAfterAuthCheckOrLogin(isAuth);
};

export const redirectToAuth = () => {
    window.location.href = `https://oauth.vk.com/authorize?client_id=${
        process.env.REACT_APP_VK_APP_ID
    }&display=page&redirect_uri=${process.env.REACT_APP_FRONT_URL}/authorization&scope=${SCOPES.join(
        ',',
    )}&response_type=code&v=${VK_API_VERSION}`;
};

export const auth = async location => {
    // Плучаем code из URI
    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    if (code === '' || code === undefined) {
        dispatch(push('/login'));
    }

    // Авторизуемся на сервере
    const serverAuthResult = await serverAuth(code);
    const isAuth = serverAuthResult.result === 'success';

    // Перенаправляем в зависимости от результата
    redirectAfterAuthCheckOrLogin(isAuth);
};

const redirectAfterAuthCheckOrLogin = isAuth => {
    const prevPage = store.getState().auth.prevPage;

    if (isAuth) {
        if (prevPage === 'login' || prevPage === 'loader') {
            dispatch(push(''));
        } else {
            dispatch(push(`/${prevPage}`));
        }
    } else {
        dispatch(push('/login'));
    }
};

export const logoutAuth = async () => {
    await logout();
    dispatch(globalsSetConstructorTheme('light'));
    dispatch(push('/login'));
};
