import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'react-router-redux';
import { numberWithSpaces, wordPad } from '../services/functions';
import { globalsSetModal } from '../store/globals/actions';
import ModalDeleteApp from './ModalDeleteApp';
import ModalCopyApp from './ModalCopyApp';

const MyApp = ({ id, title, logo, members }) => {
    const dispatch = useDispatch();

    const clickApp = e => {
        if (!e.target.classList.contains('my-app__action')) {
            dispatch(push('/edit-app-' + id));
        }
    };

    const clickCopy = () => {
        dispatch(globalsSetModal(<ModalCopyApp id={id} title={title} />));
    };

    const clickStats = () => {};

    const clickDelete = () => {
        dispatch(globalsSetModal(<ModalDeleteApp id={id} title={title} />));
    };

    const renderLogo = () => (logo === undefined || logo === '' ? {} : { backgroundImage: `url(${logo})` });

    return (
        <div className="my-app" onClick={clickApp}>
            <div className="my-app__logo" style={renderLogo()} />
            <div className="my-app__title">{title}</div>
            <div className="my-app__users">{`${numberWithSpaces(members)} ${wordPad(
                members,
                'участник',
                'участника',
                'участников',
            )}`}</div>
            <div className="my-app__control">
                <div className="my-app__action my-app__action_copy" onClick={clickCopy} />
                <div className="my-app__action my-app__action_stats" onClick={clickStats} />
                <div className="my-app__action my-app__action_delete" onClick={clickDelete} />
            </div>
        </div>
    );
};

MyApp.propTypes = {
    id: PropTypes.number,
    title: PropTypes.string,
    members: PropTypes.number,
};

export default MyApp;
