import { PROPERTIES_BUTTON } from '../settings-and-properties/properties/button';

export const COMPONENT_BUTTON_TRANSPARENT = {
    button_transparent: {
        componentName: 'MCButton',
        ...PROPERTIES_BUTTON('button', [
            ['rgba(255,255,255,0)', '#454647'],
            ['rgba(25,25,26,0)', '#e1e3e6'],
        ]),
    },
};
