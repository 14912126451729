import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { redirectToAuth, checkAuth } from '../services/auth';
import { globalsSetConstructorTheme } from '../store/globals/actions';
import Button from '../components/Button';
import '../../styles/page-login.scss';

const PageLogin = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(globalsSetConstructorTheme('light'));
        checkAuth();
    }, []);

    return (
        <div className="page-login">
            <div className="page-login__logo" />
            <div className="page-login__block">
                <div className="page-login__title">Вход в систему</div>
                <div className="page-login__button">
                    <Button clickFunc={redirectToAuth}>Войти с помощью ВКонтакте</Button>
                </div>
            </div>
        </div>
    );
};

export default PageLogin;
