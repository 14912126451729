import { ICON_DELETE } from '../../icons';

export const COMPONENT_HEADER_CONTROL_SETTINGS = {
    componentControl: {
        properties: [
            {
                headTitle: 'Управление блоком',
                settings: [
                    {
                        type: 'switch-visibility',
                        title: 'Отображение',
                        size: '1-2',
                        noStruct: true,
                    },
                    {
                        type: 'button-delete',
                        title: null,
                        size: '1-2',
                        noStruct: true,
                        icon: ICON_DELETE,
                        buttonType: 'danger',
                        children: 'Удалить',
                    },
                ],
            },
        ],
    },
};
