import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { globalsCloseModal } from '../store/globals/actions';
import Modal from './Modal';
import Button from './Button';

const ModalError = ({ title, description }) => {
    const dispatch = useDispatch();
    const [isTop, setIsTop] = useState(false);
    const textElementRef = useRef(null);

    useEffect(() => {
        if (textElementRef.current.clientHeight > 40) {
            setIsTop(true);
        }
    }, []);

    const closeModal = () => {
        dispatch(globalsCloseModal());
    };

    const renderDescription = () => {
        if (Array.isArray(description)) {
            return description.map((desc, i) => <div key={desc + i}>{desc}</div>);
        } else {
            return description;
        }
    };

    return (
        <Modal
            id="error"
            title={title === undefined ? 'Ошибка' : title}
            width={564}
            bodyBottom={8}
            rightButtons={[
                <Button key="panel-add-btn-cancel" type="secondary" clickFunc={closeModal}>
                    Закрыть
                </Button>,
            ]}
        >
            <div className={`error-content${isTop ? ' error-content_is-top' : ''}`}>
                <div className="error-content__icon" />
                <div className="error-content__text" ref={textElementRef}>
                    {renderDescription()}
                </div>
            </div>
        </Modal>
    );
};

ModalError.propTypes = {
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.array]),
};

export default ModalError;
