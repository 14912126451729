import React from 'react';
import { COMPONENTS } from '../constants/components/components';
import { COMPONENT_SETTINGS } from '../constants/components/components-settings';
import { CONTAINER_SETTINGS } from '../constants/components/settings-and-properties/container-settings';
import { COMPONENT_CONTROL_SETTINGS } from '../constants/components/settings-and-properties/component-control-settings';
import { COMPONENTS_LIST } from '../constants/components/components-list';
import { MCHeader, MCButton, MCLabel, MCImage, MCSeparator } from 'mave-ui';
import md5 from 'crypto-js/md5';

const EditorComponents = () => {
    const getComponent = (type, preparedData, num, isSpaceGray) => {
        const props = {
            ...preparedData,
            key: num + md5(JSON.stringify(preparedData)),
            isSpaceGray,
        };

        switch (type) {
            default:
            case '':
                return <></>;

            case 'button':
            case 'button_secondary':
            case 'button_transparent':
                return <MCButton {...props} />;

            case 'label':
                return <MCLabel {...props} />;

            case 'image':
                return <MCImage {...props} />;

            case 'header':
                return <MCHeader {...props} />;

            case 'separator':
                return <MCSeparator {...props} />;
        }
    };

    const getComponentDefaultData = type => {
        const defaultData = {};

        let componentSettings;
        if (COMPONENT_SETTINGS[type].noContainer === undefined || COMPONENT_SETTINGS[type].noContainer === false) {
            componentSettings = [
                ...COMPONENT_SETTINGS[type].properties,
                ...CONTAINER_SETTINGS.container.properties,
                ...COMPONENT_CONTROL_SETTINGS.componentControl.properties,
            ];
        } else {
            componentSettings = [
                ...COMPONENT_SETTINGS[type].properties,
                ...COMPONENT_CONTROL_SETTINGS.componentControl.properties,
            ];
        }

        componentSettings.forEach(cs => {
            cs.settings.forEach(s => {
                if (s.noStruct === undefined || s.noStruct === false) {
                    if (s.struct !== undefined) {
                        defaultData[s.struct] = s.defaultValue;
                    } else {
                        defaultData[s.structLight] =
                            s.defaultValueLight !== undefined ? s.defaultValueLight : s.defaultValue;
                        defaultData[s.structDark] =
                            s.defaultValueDark !== undefined ? s.defaultValueDark : s.defaultValue;
                    }
                }
            });
        });

        return defaultData;
    };

    const getComponentPreparedDataAndStyle = (data, num) => {
        const constructDataObject = (namesArray, value) => {
            const newObj = {};

            if (namesArray.length === 1) {
                newObj[namesArray.shift()] = value;
            } else {
                newObj[namesArray.shift()] = constructDataObject(namesArray, value, newObj);
            }

            return newObj;
        };

        let preparedData = {};
        Object.keys(data).forEach(key => {
            const namesArray = key.split('.');
            const dataObject = constructDataObject(namesArray, data[key]);

            const firstKey = Object.keys(dataObject)[0];

            if (preparedData[firstKey] === undefined && firstKey !== 'helper') {
                preparedData[firstKey] = {};
            }

            if (firstKey === 'style') {
                const alias = Object.keys(dataObject[firstKey])[0];
                if (preparedData[firstKey][alias] === undefined) {
                    preparedData[firstKey][alias] = {};
                }

                const theme = Object.keys(dataObject[firstKey][alias])[0];
                preparedData[firstKey][alias][theme] = {
                    ...preparedData[firstKey][alias][theme],
                    ...dataObject[firstKey][alias][theme],
                };
            } else if (firstKey === 'data') {
                const alias = Object.keys(dataObject[firstKey])[0];
                preparedData[firstKey][alias] = {
                    ...preparedData[firstKey][alias],
                    ...dataObject[firstKey][alias],
                };
            } else if (firstKey === 'actions') {
                const alias = Object.keys(dataObject[firstKey])[0];
                if (preparedData[firstKey][alias] === undefined) {
                    preparedData[firstKey][alias] = {};
                }

                const action = Object.keys(dataObject[firstKey][alias])[0];

                const key = Object.keys(dataObject[firstKey][alias][action])[0];
                if (key === 'data') {
                    if (preparedData[firstKey][alias][action] === undefined) {
                        preparedData[firstKey][alias][action] = {};
                    }

                    preparedData[firstKey][alias][action][key] = {
                        ...preparedData[firstKey][alias][action][key],
                        ...dataObject[firstKey][alias][action][key],
                    };
                } else {
                    preparedData[firstKey][alias][action] = {
                        ...preparedData[firstKey][alias][action],
                        ...dataObject[firstKey][alias][action],
                    };
                }

                const actionName = Object.keys(dataObject[firstKey][alias])[0].split('#')[0];
                preparedData[firstKey][alias][action] = {
                    ...preparedData[firstKey][alias][action],
                    action: actionName,
                };
            }
        });

        // Преобразуем объекты экшенов в массивы
        if (preparedData.actions !== undefined) {
            const newActions = {};
            Object.keys(preparedData.actions).forEach(alias => {
                const actionsArray = [];
                Object.keys(preparedData.actions[alias]).forEach(action => {
                    actionsArray.push(preparedData.actions[alias][action]);
                });
                newActions[alias] = actionsArray;
            });

            preparedData.actions = newActions;
        }

        return preparedData;
    };

    const getComponentName = type => {
        return COMPONENT_SETTINGS[type] !== undefined ? COMPONENT_SETTINGS[type].componentName : '';
    };

    const getComponentTitle = type => {
        return COMPONENTS[type] !== undefined ? COMPONENTS[type].title : 'Компонент';
    };

    const getComponentImages = type => {
        return COMPONENTS[type] !== undefined ? COMPONENTS[type].images : '';
    };

    const getComponentIcon = type => {
        let icon = '';
        Object.keys(COMPONENTS_LIST).forEach(listKey => {
            COMPONENTS_LIST[listKey].components.forEach(component => {
                if (icon === '' && component.name === type) {
                    icon = COMPONENTS_LIST[listKey].icon;
                }
            });
        });

        return icon;
    };

    return {
        getComponent,
        getComponentDefaultData,
        getComponentPreparedDataAndStyle,
        getComponentTitle,
        getComponentName,
        getComponentImages,
        getComponentIcon,
    };
};

export default EditorComponents;
