import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { checkAuth } from './js/services/auth';
import { authSetFirstAuthChecked } from './js/store/auth/actions';
import PageLoader from './js/containers/PageLoader';
import Routing from './js/classes/Routing';
import ImagesPreloader from './js/classes/ImagesPreloader';
import '@vkontakte/vkui/dist/vkui.css';
import './styles/_variables.scss';
import './styles/_fonts.scss';
import './styles/style.scss';

const App = ({ pageId }) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const firstAuthChecked = useSelector(state => state.auth.firstAuthChecked);
    const isLoadingSomething = useSelector(state => state.globals.isLoadingSomething);
    const activePanel = firstAuthChecked ? Routing().getPanel(dispatch, pageId) : <PageLoader />;
    const loaderPanel = isLoadingSomething ? <PageLoader /> : null;
    const modal = useSelector(state => state.globals.modal);

    useEffect(() => {
        ImagesPreloader().preload();

        (async () => {
            if (location.pathname !== '/authorization') {
                await checkAuth();
            } else {
                dispatch(authSetFirstAuthChecked(true));
            }
        })();
    }, []);

    return (
        <>
            {activePanel}
            {loaderPanel}
            {modal}
        </>
    );
};

App.propTypes = {
    pageId: PropTypes.string,
};

App.defaultProps = {
    pageId: '',
};

export default App;
