import { AUTO_SAVE_TIME } from './js/services/locals';
import { globalsSetProjectTimeout } from './js/store/globals/actions';
import { saveApp } from './js/services/axios';
import ProjectJSONLoaderAndSaver from './js/classes/ProjectJSONLoaderAndSaver';

const jsonSaver = () => store => next => action => {
    const saveActions = [
        'EDITOR_ADD_COMPONENT',
        'EDITOR_COMPONENT_DOWN',
        'EDITOR_COMPONENT_UP',
        'EDITOR_COMPONENT_COPY',
        'EDITOR_COMPONENT_DELETE',
        'EDITOR_COMPONENT_PREVIEW',
        'EDITOR_SET_COMPONENT_DATA',
        'EDITOR_SET_IS_DARK_THEME',
        'EDITOR_SET_IS_IOS',
        'EDITOR_SET_PHONE_SCREEN',
        'EDITOR_ADD_PANEL',
        'EDITOR_DELETE_PANEL',
        'EDITOR_SET_STARTUP_PANEL',
        'EDITOR_SET_PANEL_TITLE',
    ];

    const saveAction = () => {
        const jsonToSave = JSON.stringify(ProjectJSONLoaderAndSaver().generate(store.getState().editor));
        saveApp(store.getState().globals.pageNum, jsonToSave);
    };

    if (!store.getState().globals.isProjectLoading && saveActions.includes(action.type)) {
        const saveTimeout = setTimeout(saveAction, AUTO_SAVE_TIME);
        store.dispatch(globalsSetProjectTimeout(saveTimeout));
    }

    return next(action);
};

export default jsonSaver;
