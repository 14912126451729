import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { globalsCloseModal, globalsSetConstructorTheme, globalsSetModal } from '../store/globals/actions';
import { getAppSettings, setIsDarkTheme } from '../services/axios';
import { logoutAuth } from '../services/auth';
import { openLink } from '../services/functions';
import HeaderMenuItem from './HeaderMenuItem';
import PageSelector from './PageSelector';
import PopupMenu from './PopupMenu';
import IosSwitch from './IosSwitch';
import ModalSettings from './ModalSettings';
import iconSettings from '../../img/icons/settings.svg';
import iconOther from '../../img/icons/other.svg';
import iconPreview from '../../img/icons/preview.svg';
import iconHome from '../../img/icons/home.svg';
import '../../styles/header-menu.scss';

const HeaderMenu = ({ isProjects = false }) => {
    const dispatch = useDispatch();
    const constructorTheme = useSelector(state => state.globals.constructorTheme);
    const vkAppId = useSelector(state => state.globals.vkAppId);

    const clickFuncExit = (e, setIsOpened) => {
        logoutAuth();
        setIsOpened(false);
    };

    const clickFuncSwitch = async () => {
        const newTheme = constructorTheme === 'dark' ? 'light' : 'dark';
        dispatch(globalsSetConstructorTheme(newTheme));

        await setIsDarkTheme(newTheme === 'dark' ? 1 : 0);
    };

    const openMyApps = () => {
        dispatch(push('/'));
    };

    const openSettingsModal = async () => {
        dispatch(globalsSetModal(<ModalSettings />));
    };

    const openApp = () => {
        openLink(`https://vk.com/app${vkAppId}`, '_blank');
    };

    return (
        <div className="header-menu">
            <div className="header-menu__left">
                {!isProjects && <HeaderMenuItem title="Мои приложения" icon={iconHome} clickFunc={openMyApps} />}
            </div>
            <div className="header-menu__center">{!isProjects && <PageSelector />}</div>
            <div className="header-menu__right">
                {!isProjects && <HeaderMenuItem title="Настройки" icon={iconSettings} clickFunc={openSettingsModal} />}
                {!isProjects && <HeaderMenuItem title="Предпросмотр" icon={iconPreview} clickFunc={openApp} />}
                <PopupMenu
                    title="Еще"
                    icon={iconOther}
                    items={[
                        <div className="menu-with-switch">
                            <div className="menu-with-switch__title">Темная тема</div>
                            <IosSwitch
                                defaultValue={constructorTheme === 'dark'}
                                changeFunc={() => {}}
                                value={constructorTheme === 'dark'}
                            />
                        </div>,
                        <div>Выйти</div>,
                    ]}
                    itemsClickFunc={[clickFuncSwitch, clickFuncExit]}
                    position="right"
                />
            </div>
        </div>
    );
};

HeaderMenu.propTypes = {
    isProjects: PropTypes.bool,
};

export default HeaderMenu;
