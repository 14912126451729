import {
    COMPONENT_IMAGE_DARK_BUTTON,
    COMPONENT_IMAGE_DARK_BUTTON_SECONDARY,
    COMPONENT_IMAGE_DARK_BUTTON_TRANSPARENT,
    COMPONENT_IMAGE_DARK_HEADER,
    COMPONENT_IMAGE_DARK_IMAGE,
    COMPONENT_IMAGE_DARK_LABEL,
    COMPONENT_IMAGE_LIGHT_BUTTON,
    COMPONENT_IMAGE_LIGHT_BUTTON_SECONDARY,
    COMPONENT_IMAGE_LIGHT_BUTTON_TRANSPARENT,
    COMPONENT_IMAGE_LIGHT_HEADER,
    COMPONENT_IMAGE_LIGHT_IMAGE,
    COMPONENT_IMAGE_LIGHT_LABEL,
} from './images';

export const COMPONENTS = {
    button: { name: 'button', title: 'Кнопка', images: [COMPONENT_IMAGE_LIGHT_BUTTON, COMPONENT_IMAGE_DARK_BUTTON] },
    button_secondary: {
        name: 'button_secondary',
        title: 'Кнопка светлая',
        images: [COMPONENT_IMAGE_LIGHT_BUTTON_SECONDARY, COMPONENT_IMAGE_DARK_BUTTON_SECONDARY],
    },
    button_transparent: {
        name: 'button_transparent',
        title: 'Кнопка прозрачная',
        images: [COMPONENT_IMAGE_LIGHT_BUTTON_TRANSPARENT, COMPONENT_IMAGE_DARK_BUTTON_TRANSPARENT],
    },
    label: { name: 'label', title: 'Надпись', images: [COMPONENT_IMAGE_LIGHT_LABEL, COMPONENT_IMAGE_DARK_LABEL] },
    image: { name: 'image', title: 'Картинка', images: [COMPONENT_IMAGE_LIGHT_IMAGE, COMPONENT_IMAGE_DARK_IMAGE] },
    header: {
        name: 'header',
        title: 'Панель заголовка',
        images: [COMPONENT_IMAGE_LIGHT_HEADER, COMPONENT_IMAGE_DARK_HEADER],
    },
    separator: {
        name: 'separator',
        title: 'Разделитель',
        images: [''],
    },
};
