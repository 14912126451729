import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkAuth } from '../services/auth';
import HeaderMenu from '../components/HeaderMenu';
import MyAppsList from '../components/MyAppsList';
import '../../styles/page-my-apps.scss';

const PageMyApps = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        checkAuth();
    }, []);

    return (
        <div className="page-my-apps">
            <HeaderMenu isProjects />
            <div className="page-my-apps__inner">
                <div className="page-my-apps__title">Мои приложения</div>
                <MyAppsList />
            </div>
        </div>
    );
};

export default PageMyApps;
