import { ICON_BOLD, ICON_ITALICS, ICON_UNDERLINE, ICON_STRIKE } from '../../../icons';

export const SETTINGS_TEXT_STYLE = alias => ({
    settings: [
        {
            type: 'tapable-button',
            title: 'Параметры текста',
            size: 'min',
            structLight: `style.${alias}.bright_light.font-weight`,
            structDark: `style.${alias}.space_gray.font-weight`,
            defaultValue: 'auto',
            isOneProperty: true,
            icon: ICON_BOLD,
            values: ['bold', 'auto'],
            rightMargin: 0,
            positionType: 'left',
        },
        {
            type: 'tapable-button',
            title: null,
            size: 'min',
            structLight: `style.${alias}.bright_light.font-style`,
            structDark: `style.${alias}.space_gray.font-style`,
            defaultValue: 'normal',
            isOneProperty: true,
            icon: ICON_ITALICS,
            values: ['italic', 'normal'],
            leftMargin: 0,
            rightMargin: 0,
            positionType: 'middle',
        },
        {
            type: 'tapable-button',
            title: null,
            size: 'min',
            structLight: `style.${alias}.bright_light.text-decoration`,
            structDark: `style.${alias}.space_gray.text-decoration`,
            defaultValue: 'none',
            isOneProperty: true,
            icon: ICON_UNDERLINE,
            values: ['underline', 'none'],
            leftMargin: 0,
            rightMargin: 0,
            positionType: 'middle',
        },
        {
            type: 'tapable-button',
            title: null,
            size: 'min',
            structLight: `style.${alias}.bright_light.text-decoration`,
            structDark: `style.${alias}.space_gray.text-decoration`,
            defaultValue: 'none',
            isOneProperty: true,
            icon: ICON_STRIKE,
            values: ['line-through', 'none'],
            leftMargin: 0,
            positionType: 'right',
        },
    ],
});

export const SETTINGS_OPEN_PANEL = alias => ({
    settings: [
        {
            type: 'select-panels',
            title: 'Открыть панель',
            size: '1-1',
            structLight: `actions.${alias}.click#1.data.id`,
            structDark: `actions.${alias}.click#1.data.id`,
            defaultValue: '',
        },
        {
            type: 'default',
            structLight: `actions.${alias}.click#1.type`,
            structDark: `actions.${alias}.click#1.type`,
            defaultValue: 'open-panel',
        },
        {
            type: 'helper',
            struct: `helper.panel-id.${alias}.click#1`,
            defaultValue: `actions.${alias}.click#1.data.id`,
        },
    ],
});
