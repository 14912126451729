import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { globalsCloseModal } from '../store/globals/actions';
import {
    editorDeletePanel,
    editorSelectPanel,
    editorSetComponentData,
    editorSetPanelTitle,
    editorSetStartupPanel,
} from '../store/editor/actions';
import Modal from './Modal';
import InputText from './InputText';
import MiniHead from './MiniHead';
import Button from './Button';

const ModalEditPanel = ({ panelKey }) => {
    const dispatch = useDispatch();
    const title = useSelector(state => state.editor.panels[panelKey].title);
    const isStartup = useSelector(state => state.editor.panels[panelKey].isStartup);
    const panels = useSelector(state => state.editor.panels);
    const currentPanel = useSelector(state => state.editor.currentPanel);
    const [name, setName] = useState(title);

    const changeName = value => {
        setName(value);
    };

    const closeModal = () => {
        dispatch(globalsCloseModal());
    };

    const setStartPage = () => {
        dispatch(editorSetStartupPanel(panelKey));
    };

    const saveChanges = () => {
        if (name === '') {
            dispatch(editorSetPanelTitle(panelKey, title));
        } else {
            dispatch(editorSetPanelTitle(panelKey, name));
        }

        closeModal();
    };

    const deletePanel = () => {
        let panelStartup = 'home';

        Object.keys(panels).forEach(pKey => {
            if (panels[pKey].isStartup) {
                panelStartup = pKey;
            }
        });

        if (currentPanel === panelKey) {
            dispatch(editorSelectPanel(panelStartup));
        }
        dispatch(editorDeletePanel(panelKey));

        checkAndDeletePanelsInSelectors();

        closeModal();
    };

    const checkAndDeletePanelsInSelectors = () => {
        Object.keys(panels).forEach(pKey => {
            panels[pKey].components.forEach((component, num) => {
                Object.keys(component.data).forEach(dataKey => {
                    const dataKeyArray = dataKey.split('.');

                    if (dataKeyArray[0] === 'helper' && dataKeyArray[1] === 'panel-id') {
                        if (component.data[component.data[dataKey]] === panelKey) {
                            dispatch(editorSetComponentData(num, component.data[dataKey], ''));
                        }
                    }
                });
            });
        });
    };

    return (
        <Modal
            id="edit-panel"
            title="Редактирование"
            width={500}
            leftButtons={[
                <Button key="panel-edit-btn-delete" type="danger" clickFunc={deletePanel} disabled={isStartup}>
                    Удалить
                </Button>,
            ]}
            rightButtons={[
                <Button key="panel-edit-btn-save" type="primary" clickFunc={saveChanges}>
                    Сохранить изменения
                </Button>,
            ]}
        >
            <MiniHead title="Название" />
            <InputText placeholder="Введите название страницы" defaultValue={name} changeFunc={changeName} />
            {isStartup && (
                <div className="started-page">
                    <div className="started-page__icon" />
                    Стартовая страница
                </div>
            )}
            {!isStartup && (
                <div className="start-page" onClick={setStartPage}>
                    <div className="start-page__icon" />
                    Сделать стартовой страницей
                </div>
            )}
        </Modal>
    );
};

ModalEditPanel.propTypes = {
    panelKey: PropTypes.string,
};

export default ModalEditPanel;
