import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    editorSelectComponent,
    editorSetDragComponentOptions,
    editorSetDragComponentType,
    editorSetDragOffset,
    editorSetDragPosition,
    editorSetIsDragging,
} from '../store/editor/actions';
import '../../styles/draggable-component.scss';

const DraggingComponent = ({ title, icon, type, images, isDrag, isDragging, dragX, dragY }) => {
    const dispatch = useDispatch();
    const constructorTheme = useSelector(state => state.globals.constructorTheme);

    const dragStart = e => {
        e.persist();
        if (e.button === 0) {
            document.body.classList.add('dragging');
            dispatch(editorSetIsDragging(true));
            dispatch(editorSetDragOffset(e.nativeEvent.offsetX, e.nativeEvent.offsetY));
            dispatch(editorSetDragPosition(e.pageX - e.nativeEvent.offsetX, e.pageY - e.nativeEvent.offsetY));
            dispatch(editorSetDragComponentType(type));
            dispatch(editorSetDragComponentOptions({ title, icon, images }));
            dispatch(editorSelectComponent(-1));
        }
    };

    const dragStyle = () => (isDrag && isDragging ? { left: dragX, top: dragY } : {});

    return (
        (!isDrag || (isDrag && isDragging)) && (
            <div
                className={`draggable-component${isDrag && isDragging ? ' is-drag' : ''}`}
                onMouseDown={dragStart}
                style={dragStyle()}
            >
                <div className="draggable-component__title">{title}</div>
                <div
                    className="draggable-component__image"
                    // style={{ backgroundImage: `url(${constructorTheme === 'light' ? images[0] : images[1]})` }}
                >
                    <img src={constructorTheme === 'light' ? images[0] : images[1]} />
                </div>
            </div>
        )
    );
};

DraggingComponent.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
    images: PropTypes.array,
    isDrag: PropTypes.bool,
    isDragging: PropTypes.bool,
    dragX: PropTypes.number,
    dragY: PropTypes.number,
};

export default DraggingComponent;
