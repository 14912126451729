import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../styles/input.scss';

const TextareaText = ({ placeholder, defaultValue, changeFunc, rows, maxLength, error, setCurrentValue }) => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        if (setCurrentValue !== undefined) {
            setValue(setCurrentValue);
        }
    }, [setCurrentValue]);

    const changeValue = e => {
        setValue(e.target.value);

        if (changeFunc !== undefined) {
            changeFunc(e.target.value);
        }
    };

    return (
        <>
            <textarea
                value={value}
                rows={rows}
                className={`textarea${error && error !== '' ? ' textarea_error' : ''}`}
                placeholder={placeholder}
                maxLength={maxLength}
                onChange={changeValue}
            />
            {error && error !== '' && <div className="textarea-error">{error}</div>}
        </>
    );
};

TextareaText.propTypes = {
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    changeFunc: PropTypes.func,
    rows: PropTypes.number,
    error: PropTypes.string,
    setCurrentValue: PropTypes.string,
};

export default TextareaText;
